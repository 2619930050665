import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import { TableContainer } from 'components/Common';
import { useGetTasks } from './useGetTasks';
import { useSelector } from 'react-redux';
import { buttonsTitles } from 'common/data';
import { SingleRowAction } from 'components/TP/Common/Tables';
import EditSourceTask from '../EditSourceTask';

function ViewSourceTasks({ setShow, show, sourceType, sourceId }) {
  const toggle = () => setShow(!show);
  const {
    tasks,
    loadingTaks,
    showOptions,
    resetInput,
    pagination,
    columns,
    getTasks,
    setResetInput,
    setShowOptions,
    actionFields,
    moduleId,
    generateColumns,
  } = useGetTasks(sourceType, sourceId);

  // console.log(tasks);
  // current module name
  const { moduleName } = useSelector((state) => ({
    moduleName: state.Module?.name,
  }));

  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isview, setIsView] = useState(false);

  // const [editModal, setEditModal] = useState(false);

  const userId = JSON.parse(localStorage.getItem('authUser'))?.id;

  const actions = {
    Header: 'Actions',
    accessor: 'actions',
    disableFilters: true,
    Cell: (cellProps) => {
      // Actions Icons in each row with its props
      const row = cellProps.row.original;

      return (
        <>
          <div className="d-flex align-items-center gap-1">
            <SingleRowAction
              onClick={() => viewTask(row)}
              iconClass="mdi mdi-eye"
              actionName={'View'}
              record={row}
            />

            {userId === row?.created_by?.id && (
              <SingleRowAction
                onClick={() => editTask(row)}
                color="text-gray-700"
                iconClass="mdi mdi-pencil"
                actionName="Edit"
                record={row}
              />
            )}
          </div>
        </>
      );
    },
  };

  const newColumns = sourceType === 'Claim' ? [...columns, actions] : columns;

  // if userId === createdUser Id

  const editTask = (row) => {
    setSelectedTask(row.id);
    setShowModal(true);
    if (isview) setIsView(false);
  };
  const viewTask = (row) => {
    setSelectedTask(row.id);
    setIsView(true);
    setShowModal(true);
  };

  return (
    <Modal isOpen={show} toggle={toggle} fullscreen>
      <ModalHeader toggle={toggle}>{`${moduleName}’s Tasks`}</ModalHeader>
      <ModalBody>
        <TableContainer
          loading={loadingTaks}
          columns={newColumns}
          data={tasks}
          pagination={pagination}
          getData={getTasks}
          resetInput={resetInput}
          setResetInput={setResetInput}
          // for show / hide columns
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          fullColumns={generateColumns(actionFields)}
          sheetName={'Staff Tasks'}
          moduleId={moduleId}
          removeActionsButtons={true}
          removeActionsColumns={true}
          removePagination={true}
        />

        {showModal && (
          <EditSourceTask
            id={selectedTask}
            setShow={setShowModal}
            show={showModal}
            sourceType={sourceType}
            sourceId={sourceId}
            isView={isview}
            setIsView={setIsView}
            refetchList={() => {
              getTasks({ page: 1, per_page: 10 });
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {buttonsTitles.close}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ViewSourceTasks;
