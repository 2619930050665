import { SingleRowAction, StickyHeaderTable } from "components/TP/Common/Tables"
import { usePageType } from "hooks"
import { isNumber } from "lodash"
import moment from "moment"
import React from "react"

const NotesTable = ({
  notes,
  handleDelete,
  handleUpdate,
  removeActionsColumn,
}) => {
  const { isProcessingPage, isViewProcessingPage, isViewPage } = usePageType()

  const tableColumns = ["Created by", "Date & Time", "Description", "Actions"]
  return (
    <StickyHeaderTable
      columns={
        removeActionsColumn
          ? tableColumns.filter(item => item !== "Actions")
          : tableColumns
      }
      isLoading={false}
      noData={notes?.length === 0}
    >
      {notes?.length > 0 &&
        notes?.map(note => (
          <tr key={note?.id}>
            <td>{note?.last_update_by?.name}</td>
            <td>
              {note?.created_at
                ? moment(note?.created_at).format("DD-MM-YYYY hh:mm")
                : ""}
            </td>
            <td>{note?.description}</td>
            {!isViewPage && !isViewProcessingPage && (
              <td>
                {/* if the note is saved in DB the user couldn’t edit or delete it */}
                {isNumber(note?.id) ? null : (
                  <div className="d-flex gap-1">
                    {/* update Actions */}
                    <SingleRowAction
                      onClick={() => handleUpdate(note)}
                      color="text-gray-700"
                      iconClass="mdi mdi-pencil"
                      actionName={"Update"}
                      record={note}
                    />
                    {/* Delete Actions */}
                    <SingleRowAction
                      onClick={() => handleDelete(note.id)}
                      color="text-danger"
                      iconClass="mdi mdi mdi-close"
                      actionName={"Delete"}
                      record={note}
                    />
                  </div>
                )}
              </td>
            )}
          </tr>
        ))}
    </StickyHeaderTable>
  )
}

export default NotesTable
