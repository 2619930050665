import { request } from "../../api_helper"
import * as url from "../../url_helper"
/*
Health care Providers
*/

// add new healthCare

// url
export const addHealthCareProvider = data =>
  request({ url: url.HEALTHCAREPROVIDER, method: "POST", data })

// get all healthCares with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllHealthCareProviders = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.HEALTHCAREPROVIDER,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// show healthCare
//url/{id}

export const showHealthCareProvider = healthCareId =>
  request({ url: `${url.HEALTHCAREPROVIDER}/${healthCareId}`, method: "GET" })

// update healthCare
//url/{id}

export const updateHealthCareProvider = data =>
  request({ url: `${url.HEALTHCAREPROVIDER}/${data.id}`, method: "PUT", data })

// delete healthCare
//url/{id}
export const deleteHealthCareProvider = healthCareId =>
  request({
    url: `${url.HEALTHCAREPROVIDER}/${healthCareId}`,
    method: "DELETE",
  })

// activate healthCare
//url/activate/{id}
export const activateHealthCareProvider = healthCareId =>
  request({
    url: `${url.HEALTHCAREPROVIDER}/${healthCareId}/activate`,
    method: "POST",
  })

// deactivate healthCare
//url/deactivate/{id}
export const deactivateHealthCareProvider = healthCareId =>
  request({
    url: `${url.HEALTHCAREPROVIDER}/${healthCareId}/deactivate`,
    method: "POST",
  })
// Update Status (white list or Black Lits) healthcare
//url/{id}/statuses
export const updateHealthCareProviderStatus = data =>
  request({
    url: `${url.HEALTHCAREPROVIDER}/${data.id}/statuses`,
    method: "POST",
    data,
  })
// Update Status (white list or Black Lits) healthcare
//url/{id}/statuses
export const getHealthcareProviderTransacions = (providerId, params) =>
  request({
    url: `/stakeholders/healthcare-provider/${providerId}/payment-order-transactions`,
    method: "GET",
    params,
  })
