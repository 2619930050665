import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const TOPTenExperts = ({ dataColors, KPI }) => {
  const apexcardUserColors = getChartColorsArray(dataColors)

  const Experts =
    KPI.data?.flatMap((item) =>
      item.experts?.flatMap((expert) => ({
        name: expert.first_name + expert.last_name,
        count: expert.dispatches_count,
      }))
    ) || []

  const series = [
    {
      name: "",
      data: Experts.length > 0 ? Experts.map((expert) => expert.count) : [],
    },
  ]

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#00BDFF"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories:
        // accidentLocations.length > 0
        //   ? accidentLocations.map((loc) => loc.name)
        //   : [],
        Experts.length > 0 ? Experts.map((expert) => expert.name) : [],
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return `$ ${val}`
    //     },
    //   },
    // },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TOPTenExperts
