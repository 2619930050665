import { useEffect, useState } from "react"
import moment from "moment"
import { USER_MODULES } from "helpers/url_helper"
import { staffTasksModuleID } from "utils/modulesIds"
import useSWR from "swr"
import { fetcher } from "utils/API/SWRFetcher"
import { apiErrorrHandler, generateColumns, NotificationMessage } from "utils"
import { getAllMedicalSourceTasks } from "helpers/Claims/MedicalOpinion/index"
import { getClaimStaffTasks } from "helpers/Claims/ClaimForm"

export function useGetTasks(sourceType, sourceId) {
  const [tasks, setTasks] = useState([])
  const [loadingTaks, setLoadingTaks] = useState(false)
  // const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState([])
  const [resetInput, setResetInput] = useState(false)
  const [pagination, setPagination] = useState({})
  const [columns, setColumns] = useState([])
  const [showAllTasks, setShowAllTasks] = useState(false)

  // fetch the permissions of staff taks module
  const url = `${USER_MODULES}/${staffTasksModuleID}`
  const { isLoading, error, data } = useSWR(
    () => (url ? [url] : null),
    ([url]) => fetcher(url),
    {
      revalidateOnFocus: false,
    }
  )

  const newData = data => {
    return data.map(task => ({
      ...task,
      users: task.users
        ? task.users.filter(user => user.is_follower === 0)
        : [],
      followers: task.users
        ? task.users.filter(user => user.is_follower === 1)
        : [],
      counter:
        task.due_date && task.start_date
          ? moment(task.due_date).diff(moment(task.start_date), "days")
          : 0,
    }))
  }
  // const userId = JSON.parse(localStorage.getItem('authUser'))?.id;

  const getTasks = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_keys,
    search_values,
    search_value,
  }) => {
    setLoadingTaks(true)
    try {
      const params = {
        page,
        per_page,
        sort_by,
        sort_type,
        search_keys,
        search_values,
        search_value,
      }
      // use correspondent api function for each source Type
      const response =
        sourceType === "Claim"
          ? await getClaimStaffTasks(sourceId, params)
          : await getAllMedicalSourceTasks(sourceId, params)
      setTasks(newData(response?.item || []))
      setPagination({
        size: response.size,
        page: response.page,
        per_page: response.per_page,
        total_pages: response.total_pages,
        total: response.total_size,
      })

      setLoadingTaks(false)
    } catch (error) {
      setLoadingTaks(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)

      console.log(error)
    }
  }

  useEffect(() => {
    const actionFields = data?.fields

    if (!actionFields?.length) return

    const generated = generateColumns(actionFields)
    const filteredColumns = generated.map(col => {
      if (col.Header === "Users") {
        return {
          ...col,
          accessor: "users",
          Cell: ({ cell: { value } }) => {
            const usersData = value
              ? value.filter(user => user.is_follower === 0)
              : []
            return usersData.map(user => user.name).join(" ,")
          },
        }
      }
      if (col.Header === "Followers") {
        return {
          ...col,
          accessor: "followers",
          Cell: ({ cell: { value } }) => {
            const followersData = value
              ? value.filter(user => user.is_follower === 1)
              : []
            return followersData.map(user => user.name).join(" ,")
          },
        }
      }
      return col
    })
    const newColumn = {
      Header: "Days Count  ",
      accessor: "counter",
      disableFilters: true,
      Cell: ({ cell: { value } }) => {
        return value
      },
    }

    filteredColumns.push(newColumn)

    setColumns(filteredColumns)
  }, [showOptions, data?.fields?.length, isLoading])

  useEffect(() => {
    if (Boolean(sourceId)) {
      getTasks({
        page: 1,
      })
    }
  }, [sourceId])

  return {
    tasks,
    loadingTaks,
    showOptions,
    resetInput,
    pagination,
    columns,
    getTasks,
    setResetInput,
    setShowOptions,
    moduleId: sourceId,
    actionFields: data?.fields,
    generateColumns,
    setShowAllTasks,
    showAllTasks,
  }
}
