import React, { Fragment } from "react"

const CustomButton = ({
  className,
  disabled,
  onClick,
  type,
  title,
  disabledTooltip,
  ariaLabel,
  isSaving,
  disableClassNames,
}) => {
  return (
    <Fragment>
      {disabled && disabledTooltip ? (
        <span
          className={`d-inline-block ${disableClassNames}`}
          tabIndex="0"
          data-toggle="tooltip"
          title={disabledTooltip}
        >
          <button
            aria-label={ariaLabel}
            type={type}
            className={className}
            disabled={disabled}
          >
            {title}
          </button>
        </span>
      ) : (
        <button
          aria-label={ariaLabel}
          type={type}
          className={className}
          disabled={disabled}
          onClick={onClick ? onClick : undefined}
        >
          {isSaving && (
            <i className="bx bx-loader bx-spin font-size-14 align-middle me-1"></i>
          )}
          {title}
        </button>
      )}
    </Fragment>
  )
}

export default CustomButton
