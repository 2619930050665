import { previewDateFormat } from "constants/TPA"
import { flatten, flattenDeep } from "lodash"
import moment from "moment"
import React from "react"
import { CSVLink } from "react-csv"
import { Card, Col, Container, Row } from "reactstrap"
import XLSX from "xlsx"

const ExportMultipleTables = () => {
  const items = [
    {
      policy: {
        id: 1,
        imported: 0,
        reference: "SOL/12",
        name: "Hassan Abdellah",
        effective_date: "2022/05/24",
        stop_date: "2024/05/24",
        currency: {
          id: 1,
          name: "US Dollars",
        },
      },
      beneficiaries: [
        {
          gender: {
            id: 36,
            name: "Male",
          },
          dependency: {
            id: 1,
            name: "Principle",
          },
          first_name: "Malek",
          father_name: "Mohamed",
          last_name: "Abdellah",
          birth_date: "2014/08/19",
        },
        {
          gender: {
            id: 37,
            name: "Female",
          },
          dependency: {
            id: 13,
            name: "Child",
          },
          first_name: "Lilian",
          father_name: "Safwat",
          last_name: "Fouad",
          birth_date: "2023/05/01",
        },
      ],
    },
    {
      policy: {
        id: 11,
        imported: 1,
        reference: "S25/12",
        name: "I Policy",
        effective_date: "2022/11/24",
        stop_date: "2024/12/24",
        currency: {
          id: 1,
          name: "US Dollars",
        },
      },
      beneficiaries: [
        {
          gender: {
            id: 36,
            name: "Male",
          },
          dependency: {
            id: 1,
            name: "Principle",
          },
          first_name: "Malek",
          father_name: "Mohamed",
          last_name: "Abdellah",
          birth_date: "2014/08/19",
        },
        {
          gender: {
            id: 37,
            name: "Female",
          },
          dependency: {
            id: 13,
            name: "Child",
          },
          first_name: "Lilian",
          father_name: "Safwat",
          last_name: "Fouad",
          birth_date: "2023/05/01",
        },
      ],
    },
  ]

  // const exportCSV = () => {
  //   /* generate worksheet and workbook */
  //   // const worksheet = XLSX.utils.json_to_sheet(items)
  //   const workbook = XLSX.utils.book_new()
  //   items.forEach((item, index) => {
  //     const worksheet = XLSX.utils.json_to_sheet([
  //       item.policy,
  //       ...item.beneficiaries,
  //     ])
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "policies", true)
  //     // const worksheet2 = XLSX.utils.json_to_sheet(item.beneficiaries)
  //     // XLSX.utils.book_append_sheet(workbook, worksheet2, "beneficiaries", true)
  //   })
  //   // XLSX.utils.book_append_sheet(workbook, worksheet, "Dates")

  //   /* fix headers */
  //   // XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Birthday"]], {
  //   //   origin: "A1",
  //   // })

  //   /* create an XLSX file and try to save to Presidents.xlsx */
  //   XLSX.writeFile(workbook, `Policies_${new Date().toISOString()}.xlsx`, {
  //     compression: true,
  //   })
  // }

  // custom CSV Data
  const customCSVData = items.map(({ policy, beneficiaries }) => [
    // policy header
    [
      "Imported",
      "Reference",
      "Name",
      "Effective Date",
      "Stop Date",
      "Currency",
    ],
    // policy data
    [
      policy.imported ? "Yes" : "No",
      policy.reference,
      policy.name,
      moment(policy.effective_date).format(previewDateFormat),
      moment(policy.stop_date).format(previewDateFormat),
      policy.currency?.name,
    ],
    // beneficiary header
    [
      "Gender",
      "Dependency",
      "First Name",
      "Father Name",
      "Last Name",
      "Birth Date",
    ],
    // beneficiary data
    ...beneficiaries.map(beneficiary => [
      beneficiary.gender.name,
      beneficiary.dependency.name,
      beneficiary.first_name,
      beneficiary.father_name,
      beneficiary.last_name,
      moment(beneficiary.birth_date).format(previewDateFormat),
    ]),
    ["****"],
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              {/* provider dropdown */}
              <Card>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => exportCSV()}
                >
                  Export
                </button> */}

                <CSVLink
                  data={flatten(customCSVData)}
                  headers={[]}
                  className="btn btn-primary"
                  filename={`${"policy"}_${new Date().toISOString()}.csv`}
                >
                  Export
                </CSVLink>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExportMultipleTables
