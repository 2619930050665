import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import LineColumnApexChart from "../Charts/LineColumnApexChart"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "components/Common/ChartsDynamicColor"

const BusinessOverView = ({ KPI }) => {
  const dataColors = '["--bs-danger","--bs-blue", "--bs-green"]'
  const apaexlineColumnColors = getChartColorsArray(dataColors)

  const lostBusiness =
    KPI?.data?.map((item) => item.lost_beneficiary_premium_sum.toFixed(2)) || []

  const newBusiness =
    KPI?.data?.map((item) => item.new_beneficiary_premium_sum.toFixed(2)) || []

  const totalBusiness =
    KPI?.data?.map((item) =>
      (
        item.lost_beneficiary_premium_sum + item.new_beneficiary_premium_sum
      ).toFixed(2)
    ) || []
  const series = [
    {
      name: "Lost Business",
      data: lostBusiness,
    },
    {
      name: "New Business",
      data: newBusiness,
    },
    {
      name: "Total",
      data: totalBusiness,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: apaexlineColumnColors,
    xaxis: {
      categories: KPI?.data?.map((item) => item.year_month),
    },
    yaxis: {
      title: {
        // text: "$",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val
        },
      },
    },
    labels: {
      formatter: function (value) {
        return `$${parseFloat(value).toFixed(2)}`
      },
    },
  }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{KPI?.name}</h4>
          <div className="mb-5">
            {/* <CardBody> */}
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              width="100%"
              height={350}
            />

            {/* </CardBody> */}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default BusinessOverView
