import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const ReportedBy = ({ KPI, dataColors }) => {
  const dountApexChartColors = getChartColorsArray(dataColors)
  const reportSet = new Set()

  KPI?.data?.forEach((item) => {
    item.reported_by?.forEach((report) => {
      reportSet.add(report.name)
    })
  })
  const reportedByNames = Array.from(reportSet)

  // console.log(KPI)
  const options = {
    labels: reportedByNames,

    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],

    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }
  const series = KPI?.data?.flatMap((item) =>
    item.reported_by?.flatMap((item) => item.count || 0)
  )

  // console.log(valuee)

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height="350"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ReportedBy
