import React, { useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import TwoSidesTable from "./TwoSidesTable"

const Tables = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              {/* provider dropdown */}
              <Card>
                <CardBody>
                  <TwoSidesTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Tables
