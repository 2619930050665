import KnobChart from "components/TP/Charts/KnobChart"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import { APIDateFormat } from "constants/TPA"
import { showKPI } from "helpers/General/dashboards_helper"
import moment from "moment"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { NotificationMessage, apiErrorrHandler } from "utils"
import ClaimsWithIdsTable from "./ClaimsWithIdsTable"

const ClaimStatusTimeInterval = ({ dateRange }) => {
  // KPI
  const [isLoading, setIsLoading] = useState(false)
  const [kpi, setKPI] = useState(null)
  const [AMBKPI, setAMBKPI] = useState([])
  const [ERKPI, setERKPI] = useState([])

  //   claims Popup
  const [isClaimModal, setIsClaimModal] = useState(false)
  const [claimIds, setClaimIds] = useState([])

  //call kpi endpoint to get data of one slug
  const getKPI = async () => {
    setIsLoading(true)
    try {
      const response = await showKPI(
        "claims-count-by-status-and-time-interval",
        {
          start_date: moment(new Date()).add(-30, "days").format(APIDateFormat),
          end_date: moment(new Date()).format(APIDateFormat),
        }
      )
      setKPI(response.item)

      // const AMBResponse = await showKPI(
      //   "claims-count-by-status-and-time-interval",
      //   {
      //     start_date: moment(new Date()).add(-30, "days").format(APIDateFormat),
      //     end_date: moment(new Date()).format(APIDateFormat),
      //     condition_keys: ["benefit_id", "type_id", "class_id"],
      //     condition_values: [218, 233, null],
      //   }
      // )

      // const ERResponse = await showKPI(
      //   "claims-count-by-status-and-time-interval",
      //   {
      //     start_date: moment(new Date()).add(-30, "days").format(APIDateFormat),
      //     end_date: moment(new Date()).format(APIDateFormat),
      //     condition_keys: ["benefit_id", "type_id", "class_id"],
      //     condition_values: [1, 233, 783],
      //   }
      // )

      // setAMBKPI(AMBResponse?.item?.data)
      // setERKPI(ERResponse?.item?.data)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  //   Select certain claims from kpi data array
  const selectClassClaim = claimType => {
    if (claimType === "AMB") {
      return kpi?.data?.find(
        item =>
          item?.type?.slug === "direct-billing-claim" &&
          !item?.class &&
          item?.benefit?.slug === "AMB"
      )
    } else if (claimType === "ER") {
      return kpi?.data?.find(
        item =>
          item?.type?.slug === "direct-billing-claim" &&
          item?.class?.slug === "class-er" &&
          item?.benefit?.slug === "IN"
      )
    } else {
      return null
    }
  }

  // refetch the KPI for every 1
  // every 1 minutes
  const ref = useRef(null)
  const refetch = () => {
    getKPI()
  }
  useEffect(() => {
    getKPI()
    ref.current = setInterval(refetch, 1 * 60 * 1000)
    return () => {
      if (ref.current) {
        clearInterval(ref.current)
      }
    }
  }, [])

  return (
    <Fragment>
      <Col>
        <Card>
          <CardBody>
            {isLoading ? (
              <MultiSkeleton length={1} title={true} />
            ) : (
              <Fragment>
                <h4 className="card-title mb-4">{kpi?.name}</h4>
                <Row className="g-0">
                  {/* ER Claims */}
                  {/* ER 3 Minutes */}
                  <Col lg="4">
                    <div className="text-center" dir="ltr">
                      <h5 className="font-size-14 mb-3">ER Up to 3 Minutes</h5>
                      <div
                        onClick={() => {
                          setIsClaimModal(true)
                          setClaimIds(
                            selectClassClaim("ER")?.slots?.slot_1?.claim_ids
                          )
                        }}
                      >
                        <KnobChart
                          value={
                            selectClassClaim("ER")?.slots?.slot_1?.count || 0
                          }
                          height={200}
                          width={150}
                          fgColor="#34c38f"
                          displayCustom={() => {
                            return false
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* ER 6 Minutes */}
                  <Col lg="4">
                    <div className="text-center" dir="ltr">
                      <h5 className="font-size-14 mb-3">ER Up to 6 Minutes</h5>
                      <div
                        onClick={() => {
                          setIsClaimModal(true)
                          setClaimIds(
                            selectClassClaim("ER")?.slots?.slot_2?.claim_ids
                          )
                        }}
                      >
                        <KnobChart
                          value={
                            selectClassClaim("ER")?.slots?.slot_2?.count || 0
                          }
                          height={200}
                          width={150}
                          fgColor="#f1734f"
                          displayCustom={() => {
                            return false
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* ER +6 Minutes */}
                  <Col lg="4">
                    <div className="text-center" dir="ltr">
                      <h5 className="font-size-14 mb-3">ER 6+ Minutes</h5>
                      <div
                        onClick={() => {
                          setIsClaimModal(true)
                          setClaimIds(
                            selectClassClaim("ER")?.slots?.slot_3?.claim_ids
                          )
                        }}
                      >
                        <KnobChart
                          value={
                            selectClassClaim("ER")?.slots?.slot_3?.count || 0
                          }
                          height={200}
                          width={150}
                          fgColor="#f46a6a"
                          displayCustom={() => {
                            return false
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* AMB Claims */}

                  {/* AMB 3 Minutes */}
                  <Col lg="4">
                    <div className="text-center" dir="ltr">
                      <h5 className="font-size-14 mb-3">AMB Up to 3 Minutes</h5>
                      <div
                        onClick={() => {
                          setIsClaimModal(true)
                          setClaimIds(
                            selectClassClaim("AMB")?.slots?.slot_1?.claim_ids
                          )
                        }}
                      >
                        <KnobChart
                          value={
                            selectClassClaim("AMB")?.slots?.slot_1?.count || 0
                          }
                          height={200}
                          width={150}
                          fgColor="#34c38f"
                          displayCustom={() => {
                            return false
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* AMB 6 Minutes */}
                  <Col lg="4">
                    <div className="text-center" dir="ltr">
                      <h5 className="font-size-14 mb-3">AMB Up to 6 Minutes</h5>
                      <div
                        onClick={() => {
                          setIsClaimModal(true)
                          setClaimIds(
                            selectClassClaim("AMB")?.slots?.slot_2?.claim_ids
                          )
                        }}
                      >
                        <KnobChart
                          value={
                            selectClassClaim("AMB")?.slots?.slot_2?.count || 0
                          }
                          height={200}
                          width={150}
                          fgColor="#f1734f"
                          displayCustom={() => {
                            return false
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* AMB +6 Minutes */}
                  <Col lg="4">
                    <div className="text-center" dir="ltr">
                      <h5 className="font-size-14 mb-3">AMB 6+ Minutes</h5>
                      <div
                        onClick={() => {
                          setIsClaimModal(true)
                          setClaimIds(
                            selectClassClaim("AMB")?.slots?.slot_3?.claim_ids
                          )
                        }}
                      >
                        <KnobChart
                          value={
                            selectClassClaim("AMB")?.slots?.slot_3?.count || 0
                          }
                          height={200}
                          width={150}
                          fgColor="#f46a6a"
                          displayCustom={() => {
                            return false
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
          </CardBody>
        </Card>
      </Col>

      {isClaimModal && (
        <ClaimsWithIdsTable
          show={isClaimModal}
          onCloseClick={() => setIsClaimModal(false)}
          claimIds={claimIds?.length > 0 ? claimIds : []}
        />
      )}
    </Fragment>
  )
}

export default ClaimStatusTimeInterval
