import { request } from "../api_helper"
import * as url from "../url_helper"

export const getAllStaffTasks = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.STAFFTASKS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// add staffTask

export const addStaffTask = (data) =>
  request({ url: url.STAFFTASKS, method: "POST", data })

// show staffTask

export const showStaffTask = (staffTaskId) =>
  request({ url: `${url.STAFFTASKS}/${staffTaskId}`, method: "GET" })

// update staffTask
//url/{id}

export const updateStaffTask = (data) =>
  request({ url: `${url.STAFFTASKS}/${data.id}`, method: "PUT", data })

// delete staffTask
//url/{id}
export const deleteStaffTask = (staffTaskId) =>
  request({
    url: `${url.STAFFTASKS}/${staffTaskId}`,
    method: "DELETE",
  })

// activate staffTask
//url/activate/{id}
export const activateStaffTask = (staffTaskId) =>
  request({
    url: `${url.STAFFTASKS}/${staffTaskId}/activate`,
    method: "POST",
  })

// deactivate staffTask
//url/deactivate/{id}
export const deactivateStaffTask = (staffTaskId) =>
  request({
    url: `${url.STAFFTASKS}/${staffTaskId}/deactivate`,
    method: "POST",
  })
