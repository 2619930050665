import React, { Fragment, useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import CarParts from "assets/images/survery/car_parts.png"
import InnerPanelsTable from "./InnerPanelsTable"
import InnerSparePartsTable from "./InnerSparePartsTable"
import { SingleRowAction, StickyHeaderTable } from "components/TP/Common/Tables"
// import { NotificationMessage } from "utils"

const Test = () => {
  const [parentId, setParentId] = useState(null)
  const [panelId, setPanelId] = useState(null)

  const [repairs, setRepairs] = useState([])
  const [replaces, setReplaces] = useState([])

  // store the open accordions
  const [open, setOpen] = useState(["1", "2"])
  // toggle the open and close of accorions
  const toggle = id => {
    if (open.find(item => item === id)) {
      setOpen(open.filter(item => item !== id))
    } else {
      setOpen([...open, id])
    }
  }

  const handleClick = (e, code) => {
    e.preventDefault()
    setParentId(code)
    // NotificationMessage("success", `You Selected the part number ${code}`)
    // const selectedPart = document.getElementById(`${code}`)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          {/* Rounded Corners White Card and Table inside it */}
          <Row className="mb-2">
            <Col xs="5">
              {/* Card To Ouptut White Card Around The Table */}
              <Fragment>
                <img
                  src={CarParts}
                  useMap="#image-map"
                  // style={{ width: "100%" }}
                />

                <map name="image-map">
                  {/* from the front to the back of the car */}
                  <Fragment>
                    <area
                      id="1"
                      target="_self"
                      alt="1"
                      title="1"
                      href="1"
                      coords="158,38,152,66,200,71,280,84,284,52,322,65,262,35,208,35,180,36,198,62,195,36,167,35,173,37,312,63,273,41,325,67,323,35,307,32"
                      shape="poly"
                      onClick={e => handleClick(e, 1)}
                    />
                    <area
                      id="2"
                      target="_self"
                      alt="2"
                      title="2"
                      href="2"
                      coords="160,84,198,68"
                      shape="rect"
                      onClick={e => handleClick(e, 2)}
                    />
                    <area
                      id="3"
                      target="_parent"
                      alt="3"
                      title="3"
                      href="3"
                      coords="283,68,324,84"
                      shape="rect"
                      onClick={e => handleClick(e, 3)}
                    />
                    <area
                      id="4"
                      target="_self"
                      alt="4"
                      title="4"
                      href="4"
                      coords="171,92,159,157,320,183,308,87,214,79,172,92,160,128,176,86,174,93,160,180,236,171"
                      shape="poly"
                      onClick={e => handleClick(e, 4)}
                    />
                    <area
                      id="11"
                      target="_self"
                      alt="11"
                      title="11"
                      href="11"
                      coords="163,190,177,252,301,249,320,191,284,176,208,174"
                      shape="poly"
                      onClick={e => handleClick(e, 11)}
                    />

                    <area
                      id="12"
                      target="_self"
                      alt="12"
                      title="12"
                      href="12"
                      coords="181,251,297,405"
                      shape="rect"
                      onClick={e => handleClick(e, 12)}
                    />
                    <area
                      id="13"
                      target="_self"
                      alt="13"
                      title="13"
                      href="13"
                      coords="171,409,308,448,311,435,244,453"
                      shape=""
                      onClick={e => handleClick(e, 13)}
                    />
                    <area
                      id="15"
                      target="_self"
                      alt="15"
                      title="15"
                      href="15"
                      coords="163,449,315,498"
                      shape="rect"
                      onClick={e => handleClick(e, 15)}
                    />
                    <area
                      id="16"
                      target="_self"
                      alt="16"
                      title="16"
                      href="16"
                      coords="156,507,180,529"
                      shape="rect"
                      onClick={e => handleClick(e, 16)}
                    />
                    <area
                      id="17"
                      target="_self"
                      alt="17"
                      title="17"
                      href="17"
                      coords="300,504,320,528"
                      shape="rect"
                      onClick={e => handleClick(e, 17)}
                    />
                    <area
                      id="18"
                      target="_self"
                      alt="18"
                      title="18"
                      href="18"
                      coords="158,532,322,571"
                      shape="rect"
                      onClick={e => handleClick(e, 18)}
                    />
                  </Fragment>

                  {/* left side of the car */}
                  <Fragment>
                    <area
                      id="5"
                      target="_self"
                      alt="5"
                      title="5"
                      href="5"
                      coords="57,84,100,120"
                      shape="rect"
                      onClick={e => handleClick(e, 5)}
                    />

                    <area
                      id="6"
                      target="_self"
                      alt="6"
                      title="6"
                      href="6"
                      coords="96,80,112,103"
                      shape="rect"
                      onClick={e => handleClick(e, 6)}
                    />
                    <area
                      id="9"
                      target="_self"
                      alt="9"
                      title="9"
                      href="9"
                      coords="72,157,28"
                      shape="circle"
                      onClick={e => handleClick(e, 9)}
                    />
                    <area
                      id="19"
                      target="_self"
                      alt="19"
                      title="19"
                      href="19"
                      coords="124,154,132,206,70,204,121,135,100,107,109,152,116,138,108,108,102,123"
                      shape="poly"
                      onClick={e => handleClick(e, 19)}
                    />
                    <area
                      id="21"
                      target="_self"
                      alt="21"
                      title="21"
                      href="21"
                      coords="69,208,132,303"
                      shape="rect"
                      onClick={e => handleClick(e, 21)}
                    />
                    <area
                      id="22"
                      target="_self"
                      alt="22"
                      title="22"
                      href="22"
                      coords="132,239,135,301,168,305,168,276,149,242"
                      shape="poly"
                      onClick={e => handleClick(e, 22)}
                    />

                    <area
                      id="36"
                      target="_self"
                      alt="36"
                      title="36"
                      href="36"
                      coords="130,216,135,240,148,234,148,234"
                      shape="poly"
                      onClick={e => handleClick(e, 36)}
                    />

                    <area
                      id="25"
                      target="_self"
                      alt="25"
                      title="25"
                      href="25"
                      coords="68,308,68,371,133,400,132,305,135,396,129,307,134,304,69,307"
                      shape="poly"
                      onClick={e => handleClick(e, 25)}
                    />
                    <area
                      id="26"
                      target="_self"
                      alt="26"
                      title="26"
                      href="26"
                      coords="136,317,171,386"
                      shape="rect"
                      onClick={e => handleClick(e, 26)}
                    />

                    <area
                      id="35"
                      target="_self"
                      alt="35"
                      title="35"
                      href="35"
                      coords="56,192,65,378"
                      shape="rect"
                      onClick={e => handleClick(e, 35)}
                    />
                    <area
                      id="31"
                      target="_self"
                      alt="31"
                      title="31"
                      href="31"
                      coords="137,404,139,430,164,410,168,395"
                      shape="poly"
                      onClick={e => handleClick(e, 31)}
                    />
                    <area
                      id="33"
                      target="_self"
                      alt="33"
                      title="33"
                      href="33"
                      coords="133,404,140,479,103,481,104,421,108,412,116,405"
                      shape="poly"
                      onClick={e => handleClick(e, 33)}
                    />
                    <area
                      id="84"
                      target="_self"
                      alt="84"
                      title="84"
                      href="84"
                      coords="115,482,134,496"
                      shape="rect"
                      onClick={e => handleClick(e, 84)}
                    />
                    <area
                      id="32"
                      target="_self"
                      alt="32"
                      title="32"
                      href="32"
                      coords="65,447,102,503"
                      shape="rect"
                      onClick={e => handleClick(e, 32)}
                    />

                    <area
                      id="29"
                      target="_self"
                      alt="29"
                      title="29"
                      href="29"
                      coords="71,414,29"
                      shape="circle"
                      onClick={e => handleClick(e, 29)}
                    />
                  </Fragment>

                  {/* Right side of the car */}
                  <Fragment>
                    <area
                      id="8"
                      target="_self"
                      alt="8"
                      title="8"
                      href="8"
                      coords="363,86,379,102"
                      shape="rect"
                      onClick={e => handleClick(e, 8)}
                    />
                    <area
                      id="7"
                      target="_self"
                      alt="7"
                      title="7"
                      href="7"
                      coords="382,81,419,120"
                      shape="rect"
                      onClick={e => handleClick(e, 7)}
                    />
                    <area
                      id="20"
                      target="_self"
                      alt="20"
                      title="20"
                      href="20"
                      coords="371,109,379,124,372,204,375,109,372,212,348,202,364,107,371,108,379,204,374,199"
                      shape="poly"
                      onClick={e => handleClick(e, 20)}
                    />

                    <area
                      id="10"
                      target="_self"
                      alt="10"
                      title="10"
                      href="10"
                      coords="407,157,28"
                      shape="circle"
                      onClick={e => handleClick(e, 10)}
                    />

                    <area
                      id="37"
                      target="_self"
                      alt="37"
                      title="37"
                      href="37"
                      coords="344,219,332,236,344,240"
                      shape="poly"
                      onClick={e => handleClick(e, 37)}
                    />
                    <area
                      id="23"
                      target="_self"
                      alt="23"
                      title="23"
                      href="23"
                      coords="348,209,411,309"
                      shape="rect"
                      onClick={e => handleClick(e, 23)}
                    />
                    <area
                      id="24"
                      target="_self"
                      alt="24"
                      title="24"
                      href="24"
                      coords="328,239,345,242,341,300,304,307"
                      shape="poly"
                      onClick={e => handleClick(e, 24)}
                    />
                    <area
                      id="27"
                      target="_self"
                      alt="27"
                      title="27"
                      href="27"
                      coords="345,307,343,394,409,309,413,371,344,394,410,308,370,304,409,309"
                      shape="poly"
                      onClick={e => handleClick(e, 27)}
                    />
                    <area
                      id="28"
                      target="_self"
                      alt="28"
                      title="28"
                      href="28"
                      coords="304,318,344,386"
                      shape="rect"
                      onClick={e => handleClick(e, 28)}
                    />
                    <area
                      id="38"
                      target="_self"
                      alt="38"
                      title="38"
                      href="38"
                      coords="306,389,337,428,343,402"
                      shape="poly"
                      onClick={e => handleClick(e, 38)}
                    />
                    <area
                      id="39"
                      target="_self"
                      alt="39"
                      title="39"
                      href="39"
                      coords="343,399,363,403,375,401,373,488,339,469"
                      shape="poly"
                      onClick={e => handleClick(e, 39)}
                    />
                    <area
                      id="47"
                      target="_self"
                      alt="47"
                      title="47"
                      href="47"
                      coords="343,480,363,495"
                      shape="rect"
                      onClick={e => handleClick(e, 47)}
                    />
                    <area
                      id="40"
                      target="_self"
                      alt="40"
                      title="40"
                      href="40"
                      coords="375,444,412,503"
                      shape="rect"
                      onClick={e => handleClick(e, 40)}
                    />

                    <area
                      id="14"
                      target="_self"
                      alt="14"
                      title="14"
                      href="14"
                      coords="410,190,421,375"
                      shape="rect"
                      onClick={e => handleClick(e, 14)}
                    />
                    <area
                      id="30"
                      target="_self"
                      alt="30"
                      title="30"
                      href="30"
                      coords="409,414,28"
                      shape="circle"
                      onClick={e => handleClick(e, 30)}
                    />
                  </Fragment>
                </map>
              </Fragment>
            </Col>

            <Col xs="7">
              {parentId && (
                <Card>
                  <CardBody>
                    <Accordion open={open} toggle={toggle}>
                      {/* panels accordions */}
                      {parentId && (
                        <AccordionItem style={{ color: "inherit" }}>
                          <AccordionHeader
                            targetId="1"
                            className="bg-primary bg-opacity-25"
                          >
                            Panels
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <InnerPanelsTable
                              setRepairs={setRepairs}
                              setReplaces={setReplaces}
                              parentId={parentId}
                              panelId={panelId}
                              setPanelId={setPanelId}
                            />
                          </AccordionBody>
                        </AccordionItem>
                      )}

                      {/* spare parts accordions */}
                      {panelId && (
                        <AccordionItem style={{ color: "inherit" }}>
                          <AccordionHeader
                            targetId="2"
                            className="bg-primary bg-opacity-25"
                          >
                            Spare Parts
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            <InnerSparePartsTable
                              setRepairs={setRepairs}
                              setReplaces={setReplaces}
                              panelId={panelId}
                            />
                          </AccordionBody>
                        </AccordionItem>
                      )}
                    </Accordion>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          {(panelId || parentId) && (
            <Row>
              <Col xs="6">
                <Card>
                  <CardTitle className="p-2">Replacements</CardTitle>
                  <CardBody>
                    <StickyHeaderTable
                      noData={replaces.length === 0 ? true : false}
                      columns={["Name", "Name(AR)", ""]}
                      isLoading={false}
                    >
                      {replaces.map(replace => (
                        <tr key={replace?.id}>
                          <td>{replace?.name}</td>
                          <td>{replace?.secondary_name}</td>
                          <td>
                            <SingleRowAction
                              onClick={() => {
                                setReplaces(prev =>
                                  prev.filter(item => item.id !== replace.id)
                                )
                              }}
                              color="text-danger"
                              iconClass="mdi mdi mdi-close"
                              actionName={"Delete"}
                              record={replace}
                            />
                          </td>
                        </tr>
                      ))}
                    </StickyHeaderTable>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                {/* Card To Ouptut White Card Around The Table */}
                <Card>
                  <CardTitle className="p-2">Repairs</CardTitle>
                  <CardBody>
                    <StickyHeaderTable
                      noData={repairs.length === 0 ? true : false}
                      columns={["Name", "Name(AR)", ""]}
                      isLoading={false}
                    >
                      {repairs.map(repair => (
                        <tr key={repair?.id}>
                          <td>{repair?.name}</td>
                          <td>{repair?.secondary_name}</td>
                          <td>
                            <SingleRowAction
                              onClick={() => {
                                setRepairs(prev =>
                                  prev.filter(item => item.id !== repair.id)
                                )
                              }}
                              color="text-danger"
                              iconClass="mdi mdi mdi-close"
                              actionName={"Delete"}
                              record={repair}
                            />
                          </td>
                        </tr>
                      ))}
                    </StickyHeaderTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Test
