import { request } from "../../api_helper"
import * as url from "../../url_helper"
/*
/Users
*/

// add new Users

// url
export const addUsers = data =>
  request({ url: url.USERS, method: "POST", data })

// get all Users with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllUsers = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: url.USERS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  })

// show users
//url/{id}

export const showUsers = usersId =>
  request({ url: `${url.USERS}/${usersId}`, method: "GET" })

// update users
//url/{id}

export const updateUsers = data =>
  request({ url: `${url.USERS}/${data.id}`, method: "PUT", data })

// delete users
//url/{id}
export const deleteUsers = usersId =>
  request({ url: `${url.USERS}/${usersId}`, method: "DELETE" })

// activate users
//url/{id}/activate
export const activateUsers = usersId =>
  request({ url: `${url.USERS}/${usersId}/activate`, method: "POST" })

// deactivate users
//url/{id}/deactivate
export const deactivateUsers = usersId =>
  request({ url: `${url.USERS}/${usersId}/deactivate`, method: "POST" })

// Update Status (white list or Black Lits) Re-insurer
//url/{id}/statuses
export const updateUserStatus = data =>
  request({
    url: `${url.USERS}/${data.id}/statuses`,
    method: "POST",
    data,
  })
// Update user on duty field
//url/{id}/update-on-duty
export const updateUserOnDuty = (userId, data) =>
  request({
    url: `${url.USERS}/${userId}/update-on-duty`,
    method: "PATCH",
    data,
  })
