import React, { Fragment, useEffect, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// UI Components
import {
  FormModal,
  FormControll,
  ModalSpinner,
  SearchSelect,
} from "components/TP/Common/Forms"

import { Alert, Col, Row } from "reactstrap"

// utility function for error
import {
  apiErrorrHandler,
  convertNullToString,
  fieldFinder,
  generateFieldValidation,
  isInputInvalid,
  NotificationMessage,
} from "utils/"
import { getAllSubModules } from "helpers/Permissions/permissions_helper"
import { showHealthCareProvider } from "helpers/Stakeholders/healthCare"
import {
  healthCareProviderFirstTabID,
  healthCareProviderModuleID,
} from "utils/modulesIds"
import { showHealthCareContract } from "helpers/Networks/healthCareContract"

const ViewClaimProviderModal = ({
  isModal,
  setIsModal,
  providerId,
  contractId,
  modalTitle,
}) => {
  // states
  const [provider, setProvider] = useState(null)
  const [contract, setContract] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  // store the inputs from permission level 3
  const [inputs, setInputs] = useState([])

  useEffect(() => {
    if (providerId) {
      getProvider(providerId)
    }
  }, [providerId])

  useEffect(() => {
    if (contractId) {
      getContract(contractId)
    }
  }, [contractId])

  const getProvider = async providerId => {
    setIsLoading(true)
    try {
      const response = await showHealthCareProvider(providerId)
      setProvider(convertNullToString(response.item))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }
  const getContract = async contractId => {
    setIsLoading(true)
    try {
      const res = await showHealthCareContract(contractId)
      setContract(convertNullToString(res.item))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  // validation with formik and Yup
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: provider ? provider.email : "",
      phone: provider ? provider.phone : "",
      address: provider ? provider.address : "",
      payment_method_id:
        provider && provider.payment_method ? provider.payment_method.name : "",
      jumbo_claim_limit: contract ? contract.jumbo_claim_limit : "",
    },
    validationSchema: Yup.object({
      email: generateFieldValidation(inputs, 103, "email"),
      phone: generateFieldValidation(inputs, 104, "string"),
      address: generateFieldValidation(inputs, 106, "string"),
      payment_method_id: generateFieldValidation(inputs, 114, "object"),
    }),
  })

  const { errors, values, getFieldProps, setFieldTouched, setFieldValue } =
    validation
  //   fetch the permissions
  const getProviderPermissions = async () => {
    setLoading(true)
    try {
      const level3Res = await getAllSubModules(healthCareProviderModuleID)
      setInputs(
        level3Res.item.sub_modules.find(
          item => item.id === healthCareProviderFirstTabID
        )?.fields
      )
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isModal) {
      getProviderPermissions()
    }
  }, [isModal])

  return (
    <FormModal
      show={isModal}
      modalSize="xl"
      onCloseClick={() => {
        setIsModal(false)
        validation.resetForm()
        setError("")
      }}
      onSaveClick={validation.handleSubmit}
      modalTitle={modalTitle}
      isSaving={validation.isSubmitting}
      noFooter={true}
      scrollable={true}
    >
      {isLoading || loading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={e => e.preventDefault()}>
          {/* API Error Message For physician Module */}

          {error ? <Alert color="danger">{error}</Alert> : null}

          {inputs && inputs.length > 0 && (
            <Fragment>
              {/* Physician Name Input */}
              <Row>
                {/* Email  Input */}
                <Col md={3}>
                  <FormControll
                    id="103"
                    name="email"
                    type="email"
                    inputField={fieldFinder(inputs, 103)}
                    {...getFieldProps("email")}
                    invalid={isInputInvalid(validation, "email")}
                    error={errors.email}
                    wrapperClassNames="mb-3"
                    readOnly={true}
                  />
                </Col>

                {/* phone Input */}
                <Col md={3}>
                  <FormControll
                    id="104"
                    name="phone"
                    type="text"
                    inputField={fieldFinder(inputs, 104)}
                    {...getFieldProps("phone")}
                    invalid={isInputInvalid(validation, "phone")}
                    error={errors.phone}
                    wrapperClassNames="mb-3"
                    readOnly={true}
                  />
                </Col>
                {/* Address Input */}
                <Col md={3}>
                  <FormControll
                    id="106"
                    name="address"
                    type="text"
                    inputField={fieldFinder(inputs, 106)}
                    {...getFieldProps("address")}
                    invalid={isInputInvalid(validation, "address")}
                    error={errors.address}
                    wrapperClassNames="mb-3"
                    readOnly={true}
                  />
                </Col>

                {/* Payment Method Input */}
                <Col md={3}>
                  <FormControll
                    id="114"
                    name="payment_method_id"
                    inputField={fieldFinder(inputs, 114)}
                    value={values.payment_method_id}
                    invalid={isInputInvalid(validation, "payment_method_id")}
                    error={errors.payment_method_id}
                    wrapperClassNames="mb-3"
                    readOnly={true}
                  />
                </Col>
                {/* Jumbo Claim Limits Input  */}
                <Col md={3}>
                  <FormControll
                    id="125"
                    name="jumbo_claim_limit"
                    label="Jumbo Claim Limit"
                    type="number"
                    // inputField={fieldFinder(inputs, 125)}
                    includeNumricFormat={true}
                    {...validation.getFieldProps("jumbo_claim_limit")}
                    onChange={setFieldValue}
                    invalid={isInputInvalid(validation, "jumbo_claim_limit")}
                    error={errors.jumbo_claim_limit}
                    wrapperClassNames="mb-3"
                    readOnly={true}
                  />
                </Col>
              </Row>
            </Fragment>
          )}
        </form>
      )}
    </FormModal>
  )
}

export default withTranslation()(ViewClaimProviderModal)
