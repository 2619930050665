import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import parse from "html-react-parser"

const LossRatio = ({ KPI }) => {
  // console.log(KPI)

  const types = KPI ? KPI.data?.map((item) => item?.type) : []
  const typesData = types[0]

  const getProgressBarColor = (calculatedValue) => {
    if (calculatedValue >= 70) {
      return "bg-success"
    } else if (calculatedValue >= 50) {
      return "bg-warning"
    } else {
      return "bg-danger"
    }
  }
  const payerCount = new Set(
    KPI?.data
      .filter((entry) => entry.type.some((type) => type.calculated_value > 0))
      .map((entry) => entry.payer.id)
  ).size
  // console.log(payerCount)

  const getTypeValuesSum = (data, type, payerCount) => {
    const sum = data.reduce((acc, curr) => {
      const typeData = curr.type.find((t) => t.name === type)
      if (typeData) {
        return acc + typeData.calculated_value
      }
      return acc
    }, 0)
    return payerCount > 0 ? (sum / payerCount).toFixed(2) : 0
  }

  const reports = [
    {
      title: "Individual <br/> Loss Ratio",
      value: getTypeValuesSum(KPI.data, "Individual", payerCount),
      badgeValue: "+ 1.2%",
      dir: "up",
      color: "yellowOrange",
      desc: "From Last Year",
    },
    {
      title: "Family <br/> Loss Ratio",
      value: getTypeValuesSum(KPI.data, "Family", payerCount),
      badgeValue: "+ 9%",
      dir: "down",
      color: "success",
      desc: "From Last Year",
    },
    {
      title: "Group <br/> Loss Ratio",
      value: getTypeValuesSum(KPI.data, "Group", payerCount),
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "down",
      desc: "From Last Year",
    },
    {
      title: "Individual <br/> Retention Ratio",
      value: "0",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Family <br/> Retention Ratio",
      value: "0",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Group <br/> Retention Ratio",
      value: "0",
      badgeValue: "+ 2.3%",
      color: "yellowOrange",
      dir: "down",
      desc: "From Last Year",
    },
  ]
  return (
    <React.Fragment>
      <Row>
        {reports.map((report) => (
          <Col md={2} key={report.title}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <h6>{parse(report.title)}</h6>
                </div>
                <div className="text-muted mt-3">
                  <h4>
                    {report.value}%
                    <i
                      className={
                        "mdi mdi-chevron-" +
                        report.dir +
                        " ms-1 text-" +
                        report.color
                      }
                    />
                  </h4>
                  <div className="d-flex">
                    <span
                      className={
                        "badge badge-soft-" + report.color + " font-size-12"
                      }
                    >
                      {" "}
                      {report.badgeValue}{" "}
                    </span>{" "}
                    <span className="ms-2 text-truncate">{report.desc}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      {/* <Card>
        <CardBody>
          <div className="d-flex flex-wrap">
            <div className="me-2">
              <h5 className="card-title mb-3">{`${KPI?.name} By Policy Type`}</h5>
            </div>
          </div>
          <hr />
          <div>
            <ul className="list-group list-group-flush">
              {KPI?.data?.map((payerData, index) =>
                payerData.type.map((type, typeIndex) => (
                  <li key={type.name} className="list-group-item mb-1">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {`${type?.name} `}
                        <span className="float-end">{`${type?.calculated_value?.toFixed()}%`}</span>
                      </h5>
                      <div className="progress animated-progess progress-sm mb-3">
                        <div
                          className={`progress-bar ${getProgressBarColor(
                            type?.calculated_value
                          )}`}
                          role="progressbar"
                          style={{ width: `${type.calculated_value}%` }}
                          aria-valuenow={type.calculated_value}
                          aria-valuemin="0"
                          aria-valuemax="200"
                        ></div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </CardBody>
      </Card> */}
    </React.Fragment>
  )
}

export default LossRatio
