import {
  DatePickerInput,
  FormControll,
  FormModal,
  SearchSelect,
} from 'components/TP/Common/Forms';
import PopUpFormTitle from 'components/TP/Common/General/PopUpFormTitle';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Col, Row } from 'reactstrap';
import {
  apiErrorrHandler,
  convertNullToString,
  fieldFinder,
  generateFieldValidation,
  isInputInvalid,
  NotificationMessage,
} from 'utils';
import * as Yup from 'yup';
import * as URLs from 'helpers/url_helper';
import {
  showStaffTask,
  updateStaffTask,
} from 'helpers/General/staff_tasks_helper';
import { UploadFiles } from 'utils/API/UploadFiles';
import DropZone from 'components/TP/Common/DropZone';
import moment from 'moment';
import { previewDateFormat } from 'constants/TPA';
import { Link } from 'react-router-dom';
import { claimPaths } from 'common/data/routePaths';

const UpdateStaffTask = ({
  isUpdateModal,
  setIsUpdateModal,
  refetchList,
  staffTaskToUpdate,
  setStaffTaskToUpdate,
}) => {
  const [error, setError] = useState('');
  const [staffTask, setStaffTask] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { fields: inputs, loading } = useSelector((state) => ({
    fields: state.Module.fields,
    loading: state.Module.loading,
  }));

  const handleUpdateTask = async (values, setSubmitting, resetForm) => {
    try {
      const fileIds = await UploadFiles(selectedFiles);
      await updateStaffTask({
        id: staffTask.id,
        ...values,
        files: fileIds,
        users: [
          ...values.users?.map((user) => ({
            user_id: user,
            is_follower: 0,
          })),

          ...values.followers?.map((user) => ({
            user_id: user,
            is_follower: 1,
          })),
        ],
      });
      NotificationMessage('success', 'add');
      resetForm();
      setIsUpdateModal(false);
      refetchList();
      setError('');
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };
  const getStaffTask = async (staffTaskId) => {
    setIsLoading(true);
    try {
      const response = await showStaffTask(staffTaskId);
      setStaffTask(convertNullToString(response.item));
      setSelectedFiles(
        (response?.item?.files?.map((item) => item?.files) || []).filter(
          (id) => id !== null && id !== undefined
        )
      );
      setStaffTaskToUpdate(null);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage('Error', errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (staffTaskToUpdate) {
      getStaffTask(staffTaskToUpdate);
    }
  }, [staffTaskToUpdate]);

  // validation with formik and Yup
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: staffTask && staffTask ? staffTask.title : '',
      description: staffTask && staffTask ? staffTask.description : '',
      status_id: staffTask && staffTask.status ? staffTask.status.id : '',
      priority_id: staffTask && staffTask.priority ? staffTask.priority.id : '',
      start_date: staffTask && staffTask ? staffTask.start_date : '',
      due_date: staffTask && staffTask ? staffTask.due_date : '',
      users:
        staffTask && staffTask.users
          ? staffTask.users
              .filter((item) => item.is_follower === 0)
              .map((item) => item.id)
          : [],

      followers:
        staffTask && staffTask.users
          ? staffTask.users
              .filter((item) => item.is_follower === 1)
              .map((item) => item.id)
          : [],
    },
    validationSchema: Yup.object({
      title: generateFieldValidation(inputs, 1570, 'string'),
      description: generateFieldValidation(inputs, 1571, 'string'),
      status_id: generateFieldValidation(inputs, 1572, 'dropdown'),
      priority_id: generateFieldValidation(inputs, 1573, 'dropdown'),
      start_date: generateFieldValidation(inputs, 1574, 'date'),
      due_date: generateFieldValidation(inputs, 1575, 'date'),
      users: generateFieldValidation(inputs, 1576, 'array'),
      followers: generateFieldValidation(inputs, 1577, 'array'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleUpdateTask(values, setSubmitting, resetForm);
    },
  });

  return (
    <FormModal
      modalSize="md"
      show={isUpdateModal}
      onCloseClick={() => {
        setIsUpdateModal(false);
        validation.resetForm();
        setError('');
      }}
      onSaveClick={validation.handleSubmit}
      modalTitle={<PopUpFormTitle formType="Detail" />}
      isSaving={validation.isSubmitting}
      hideSaveButton={
        staffTask &&
        staffTask.users?.some(
          (item) =>
            item.is_follower === 1 &&
            item.id === JSON.parse(localStorage.getItem('authUser'))?.id
        )
      }
    >
      {staffTask && staffTask.source?.reference && (
        <Row>
          <Col md={12}>
            <Link
              className="font-size-14 text-primary "
              to={claimPaths.claimForm + `/view/${staffTask.source?.id}`}
              target="_blank"
            >
              Claim: {staffTask.source?.reference}
            </Link>
          </Col>
        </Row>
      )}
      <form onSubmit={(e) => e.preventDefault()}>
        {error ? <Alert color="danger">{error}</Alert> : null}

        {/* {inputs && inputs.length > 0 && ( */}
        <Fragment>
          <Row>
            {/* <Col md={3}> */}
            <FormControll
              id="1570"
              name="title"
              type="text"
              inputField={fieldFinder(inputs, 1570)}
              {...validation.getFieldProps('title')}
              invalid={isInputInvalid(validation, 'title')}
              error={validation.errors.title}
              wrapperClassNames="mb-3"
            />
            {/* </Col> */}
            <Col md={6}>
              <DatePickerInput
                id="1574"
                name="start_date"
                inputField={fieldFinder(inputs, 1574)}
                value={validation.values.start_date}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                invalid={isInputInvalid(validation, 'start_date')}
                error={validation.errors.start_date}
                wrapperClassNames="mb-4"
                disablePastDates={true}
              />
            </Col>
            <Col md={6}>
              <DatePickerInput
                id="1575"
                name="due_date"
                inputField={fieldFinder(inputs, 1575)}
                value={validation.values.due_date}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                invalid={isInputInvalid(validation, 'due_date')}
                error={validation.errors.due_date}
                wrapperClassNames="mb-4"
                removeDisable={true}
                minDate={validation.values.start_date}
              />
            </Col>
            <Col md={6}>
              <SearchSelect
                id="1573"
                name="priority_id"
                inputField={fieldFinder(inputs, 1573)}
                value={validation.values.priority_id || null}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                mappingLabel="name"
                mappingValue="id"
                url={`${URLs.PREDEFINEDS}/${
                  fieldFinder(inputs, 1573)?.predefined_value?.id
                }`}
                invalid={isInputInvalid(validation, 'priority_id')}
                error={validation.errors.priority_id}
                wrapperClassNames="mb-3"
              />
            </Col>
            <Col md={6}>
              <SearchSelect
                id="1572"
                name="status_id"
                inputField={fieldFinder(inputs, 1572)}
                value={validation.values.status_id || null}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                mappingLabel="name"
                mappingValue="id"
                url={`${URLs.PREDEFINEDS}/${
                  fieldFinder(inputs, 1572)?.predefined_value?.id
                }`}
                invalid={isInputInvalid(validation, 'status_id')}
                error={validation.errors.status_id}
                wrapperClassNames="mb-3"
              />
            </Col>
            <Col md={6}>
              <SearchSelect
                id="1576"
                name="users"
                inputField={fieldFinder(inputs, 1576)}
                value={validation.values.users}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                mappingLabel="name"
                mappingValue="id"
                url={URLs.USERS}
                disableSorting={true}
                generateDisabledOptions={(option) => option.is_active === 0}
                invalid={isInputInvalid(validation, 'users')}
                error={validation.errors.users}
                wrapperClassNames="mb-3"
                multiple={true}
              />
            </Col>
            <Col md={6}>
              <SearchSelect
                id="1577"
                name="followers"
                inputField={fieldFinder(inputs, 1577)}
                value={validation.values.followers || null}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                mappingLabel="first_name"
                mappingValue="id"
                url={URLs.USERS}
                invalid={isInputInvalid(validation, 'followers')}
                error={validation.errors.followers}
                wrapperClassNames="mb-3"
                multiple={true}
              />
            </Col>
            <FormControll
              id="1571"
              name="description"
              type="textarea"
              inputField={fieldFinder(inputs, 1571)}
              {...validation.getFieldProps('description')}
              invalid={isInputInvalid(validation, 'description')}
              error={validation.errors.description}
              wrapperClassNames="mb-3"
            />
          </Row>
        </Fragment>
        {/* )} */}
        <DropZone
          selectedFiles={selectedFiles}
          setselectedFiles={setSelectedFiles}
        />
      </form>
      <ul className="list-unstyled hstack gap-2 flex-wrap pt-4">
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-calendar font-size-14 text-primary"></i>
          <span className="fw-bold">Created At</span>
          <span>
            {moment(staffTask?.created_at).format(previewDateFormat)}{' '}
            {moment(staffTask?.created_at).format('hh:mm a')}
          </span>
        </li>

        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-user font-size-14 text-primary"></i>
          <span className="fw-bold">Created By</span>
          <span>{staffTask?.created_by?.name}</span>
        </li>
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-calendar font-size-14 text-primary"></i>
          <span className="fw-bold">Last Updated At</span>
          <span>
            {moment(staffTask?.updated_at).format(previewDateFormat)}{' '}
            {moment(staffTask?.updated_at).format('hh:mm a')}
          </span>
        </li>
        <li className="d-flex gap-2 align-items-center">
          <i className="bx bx-user font-size-14 text-primary"></i>
          <span className="fw-bold">Last Updated By</span>
          <span>{staffTask?.last_update_by?.name}</span>
        </li>
      </ul>
    </FormModal>
  );
};

export default UpdateStaffTask;
