import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, Col, Row } from "reactstrap"

const AccidentByDate = ({ dataColors, KPI }) => {
  const apexcardUserColors = getChartColorsArray(dataColors)

  const accidents =
    KPI?.data?.flatMap((item) =>
      item.accident_dates?.flatMap((accident) => ({
        date: accident?.accident_date ?? "",
        count: accident?.count ?? 0,
      }))
    ) || []

  const series = [
    {
      name: "",
      data:
        accidents?.length > 0
          ? accidents.map((accident) => accident?.count ?? 0)
          : [],
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexcardUserColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories:
        accidents.length > 0
          ? accidents.map((accident) => accident?.date ?? "")
          : [],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    // legend: {
    //   position: "top",
    //   horizontalAlign: "right",
    // },
  }

  return (
    <Card>
      <CardBody>
        <div className="d-flex flex-wrap">
          <h5 className="card-title me-2">{KPI?.name}</h5>
          <div className="ms-auto">
            {/* <div className="toolbar button-items text-end">
              <button type="button" className="btn btn-light btn-sm">
                ALL
              </button>{" "}
              <button type="button" className="btn btn-light btn-sm">
                1M
              </button>{" "}
              <button type="button" className="btn btn-light btn-sm">
                6M
              </button>{" "}
              <button type="button" className="btn btn-light btn-sm active">
                1Y
              </button>
            </div> */}
          </div>
        </div>

        {/* <Row className="text-center">
          <Col lg={4}>
            <div className="mt-4">
              <p className="text-muted mb-1">Today</p>
              <h5>1024</h5>
            </div>
          </Col>

          <Col lg={4}>
            <div className="mt-4">
              <p className="text-muted mb-1">This Month</p>
              <h5>
                12356{" "}
                <span className="text-success font-size-13">
                  0.2 % <i className="mdi mdi-arrow-up ms-1"></i>
                </span>
              </h5>
            </div>
          </Col>

          <Col lg={4}>
            <div className="mt-4">
              <p className="text-muted mb-1">This Year</p>
              <h5>
                102354{" "}
                <span className="text-success font-size-13">
                  0.1 % <i className="mdi mdi-arrow-up ms-1"></i>
                </span>
              </h5>
            </div>
          </Col>
        </Row> */}

        <hr className="mb-4" />
        <div className="apex-charts" id="area-chart" dir="ltr">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
            className="apex-charts"
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default AccidentByDate
