export const claimPaths = {
  claimForm: '/claim-form',
  claimValidation: '/eligibility-verification',
  claimView: '/eligibility-verification-view',
  claimBatch: '/batch-claim',
  reinmbursementClaim: '/reimbursement-claim',
  chronicForm: '/chronic-form',
  claimProcessing: '/claims-processing',
  viewProcessing: 'view-processing',
  claimAssignment: '/claim-assignment',
  arbitrationInvoices: '/arbitration-invoices',
  medicalOpinion: '/medical-opinion',
  medicalReview: '/medical-review',
  unreadChats: '/unread-chats',
  approvalRequests: '/approval-requests',
};
