import React from "react"
import { Skeleton } from "antd"

const TableSkeleton = ({ loadingRows }) => {
  return (
    <Skeleton
      style={{ width: "100%" }}
      active
      title={false}
      paragraph={{
        rows: loadingRows ? loadingRows : 15,
      }}
    />
  )
}

export default TableSkeleton
