import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import {
  claimDashboardID,
  dashboardsID,
  liveTrackingDashboardID,
} from "utils/modulesIds"
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import { CardUser, FOBChart, KPIInfoCard } from "components/TP/dashboards"
import { showDashboard } from "helpers/General/dashboards_helper"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { useSelector } from "react-redux"
import { APIDateFormat, CompanyName } from "constants/TPA"
import moment from "moment"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import dayjs from "dayjs"
import { ModalSpinner } from "components/TP/Common/Forms"
import ClaimsNumber from "components/TP/dashboards/ClaimsNumber"
import ClaimStatusTimeInterval from "./ClaimStatusTimeInterval"

const LiveTrackingDashboard = props => {
  const { module, loading } = useSelector(state => ({
    module: state.Sidebar?.modules
      ?.find(item => item.id === dashboardsID)
      ?.sub_modules?.find(item => item.id === liveTrackingDashboardID),
    loading: state.Sidebar?.loading,
  }))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={module?.title} />

          {/* claims-count-by-status-and-time-interval */}
          <Row>{<ClaimStatusTimeInterval />}</Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

LiveTrackingDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LiveTrackingDashboard)
