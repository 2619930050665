import { buttonsTitles, modalHeaders, staticFieldLabels } from "common/data"
import { getBeneficiaryItemServices } from "helpers/Production/Policies/beneficaries_helper"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Modal, Row } from "reactstrap"
import {
  NotificationMessage,
  apiErrorrHandler,
  fieldFinder,
  generateColumns,
} from "utils"
import { DateRangePicker, ModalSpinner } from "../Common/Forms"
import dayjs from "dayjs"
import { APIDateFormat } from "constants/TPA"
import moment from "moment"
import { TableContainer } from "components/Common"
import { flatten } from "lodash"
import MultiSkeleton from "../Common/MultiSkeleton"

const ItemServicesModal = ({
  beneficiaryId,
  actionFields = [],
  admissionFields,
}) => {
  const [isModal, setIsModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [columns, setColumns] = useState([])
  const [claims, setClaims] = useState([])

  // for date range
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat),
    end_date: dayjs().format(APIDateFormat),
  })

  const getItemServices = async () => {
    try {
      setIsLoading(true)
      const res = await getBeneficiaryItemServices(beneficiaryId, {
        from_admission_date: moment(dateRange.start_date).format(
          APIDateFormat.replaceAll("/", "-")
        ),
        to_admission_date: moment(dateRange.end_date).format(
          APIDateFormat.replaceAll("/", "-")
        ),
      })
      //   combine the services with the claim
      const combinedData =
        res?.item?.length > 0
          ? res?.item?.map(item =>
              item?.services?.map(service => ({
                ...service,
                service_id: service.id,
                ...item,
              }))
            )
          : []

      setClaims(flatten(combinedData))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    if (isModal && beneficiaryId) {
      getItemServices()
    }
  }, [isModal, beneficiaryId])

  console.log("claims", claims)
  useEffect(() => {
    if (actionFields?.length > 0) {
      const generatedColumns = generateColumns([
        ...actionFields,

        { ...fieldFinder(admissionFields, 712), display_order: 4.1 },

        fieldFinder(admissionFields, 407),
        fieldFinder(admissionFields, 415),
        {
          label_name: "Service Code",
          index_name: "service.code",
          name: "service.code",
        },
        {
          label_name: "Service Name",
          index_name: "service.name",
          name: "service.name",
        },
        {
          label_name: "Service Group",
          index_name: "service.ancestors",
          name: "service.ancestors",
        },
        {
          label_name: "Service Status",
          index_name: "action.name",
          name: "action.name",
        },
      ])

      setColumns([
        ...generatedColumns.filter(
          item =>
            item.accessor !== "beneficiary.name" &&
            item.accessor !== "beneficiary.code" &&
            item.accessor !== "beneficiary.policy.payer.abbreviation" &&
            item.accessor !== "claim_assigned_user.user.name" &&
            item.accessor !== "beneficiary.policy.name" &&
            item.accessor !== "estimation_amount" &&
            item.accessor !== "total_claimed_amount" &&
            item.accessor !== "total_approved_amount" &&
            item.accessor !== "cause.name" &&
            item.accessor !== "internal_notes" &&
            item.accessor !== "authorization_notes" &&
            item.accessor !== "routed_to_user.name" &&
            item.accessor !== "updated_at" &&
            item.accessor !== "status.name"
        ),
      ])
    }
  }, [actionFields.length])

  return (
    <Fragment>
      {/* Trigger */}
      <div className="d-flex mb-4 align-items-center justify-content-start">
        <button
          type="button"
          className="btn btn-gray-600"
          onClick={() => setIsModal(true)}
        >
          {buttonsTitles.itemServices}
        </button>
      </div>

      {/* Modal */}
      <Modal
        size="xl"
        isOpen={isModal}
        toggle={() => setIsModal(false)}
        backdrop="static"
        className="modal-fullscreen"
      >
        <div className="modal-header">
          {/* Modal Header Title */}
          <h5 className="modal-title mt-0" id="myModalLabel">
            {modalHeaders.itemServices}
          </h5>

          {/* close button on the top right section */}
          <button
            aria-label="Close Modal"
            type="button"
            onClick={() => setIsModal(false)}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
        </div>

        {/* Modal Body */}
        <div className="modal-body">
          <Fragment>
            {isLoading ? (
              <MultiSkeleton length={1} />
            ) : (
              <Row className="mb-3">
                <Col md={4}>
                  <DateRangePicker
                    label={staticFieldLabels.fromToDate}
                    value={dateRange}
                    setDateRange={setDateRange}
                    noPresets={true}
                  />
                </Col>
                <Col md={1}>
                  <button
                    type="button"
                    className="btn btn-primary mt-4"
                    onClick={getItemServices}
                    disabled={
                      !dateRange.start_date || !dateRange.end_date
                        ? true
                        : false
                    }
                  >
                    {buttonsTitles.submit}
                  </button>
                </Col>
              </Row>
            )}

            <TableContainer
              removeActionsColumns={true}
              removeActionsButtons={true}
              columns={columns}
              data={claims}
              handleAdd={null}
              hidePagination={true}
              getData={getItemServices}
              loading={isLoading}
              basicTable={true}
            />
          </Fragment>
        </div>

        <div className="modal-footer">
          <button
            aria-label="Close Modal"
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsModal(false)}
          >
            {buttonsTitles.close}
          </button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default ItemServicesModal
