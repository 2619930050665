import { buttonsTitles } from "common/data"
import { claimPaths } from "common/data/routePaths"
import { TableContainer } from "components/Common"
import { SingleRowAction } from "components/TP/Common/Tables"
import { getAllClaims } from "helpers/Claims/ClaimForm"
import { getAllActions } from "helpers/Permissions/permissions_helper"
import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import { NotificationMessage, apiErrorrHandler, generateColumns } from "utils"
import { claimFormModuleId } from "utils/modulesIds"

const ClaimsWithIdsTable = ({ show, onCloseClick, claimIds }) => {
  // claims
  const [claims, setClaims] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({})

  // columns
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [actionFields, setActionFields] = useState([])
  const [actions, setActions] = useState([])

  useEffect(() => {
    if (actionFields?.length > 0) {
      const generatedColumns = generateColumns(actionFields)
      setColumns([
        ...generatedColumns,

        {
          Header: "Actions",
          accessor: "actions",
          disableFilters: true,
          Cell: cellProps => {
            // Actions Icons in each row with its props
            const row = cellProps.row.original
            return (
              actions.find(item => item.id === 8) && (
                <SingleRowAction
                  record={row}
                  target="_blank"
                  to={`${claimPaths.claimForm}/view/${row?.id}`}
                  iconClass="mdi mdi-eye"
                  actionName={actions.find(item => item.id === 8)?.name}
                />
              )
            )
          },
        },
      ])
    }
  }, [actionFields.length])

  const getClaims = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    claim_ids,
  }) => {
    try {
      if (claim_ids?.length === 0) {
        setClaims([])
        return
      }
      setIsLoading(true)
      const res = await getAllClaims({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        claim_ids: claim_ids,
      })

      setClaims(res.items)
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  const getClaimPermission = async () => {
    setLoading(true)
    try {
      const level2Res = await getAllActions(claimFormModuleId)
      setActionFields(level2Res.item.fields)
      setActions(level2Res.item.actions)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    } finally {
      setLoading(false)
    }
  }

  // fetch claim permission if the component isn’t in the claim form

  useEffect(() => {
    if (show) {
      getClaimPermission()
    }
  }, [show])

  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      className="modal-fullscreen"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          Claims
        </h5>

        {/* close button on the top right section */}
        <button
          aria-label="Close Modal"
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <TableContainer
          removeActionsColumns={true}
          removeActionsButtons={true}
          columns={columns}
          data={claims}
          pagination={pagination}
          queryAddOn={{ claim_ids: claimIds }}
          getData={getClaims}
          loading={isLoading}
          basicTable={true}
          sheetName="Claims"
        />
      </div>

      <div className="modal-footer">
        <button
          aria-label="Close Modal"
          type="button"
          className="btn btn-secondary"
          onClick={onCloseClick}
        >
          {buttonsTitles.close}
        </button>
      </div>
    </Modal>
  )
}

export default ClaimsWithIdsTable
