import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import {
  AsyncSearchSelect,
  DateRangePicker,
  SearchSelect,
} from "../Common/Forms"
import { getAllPayer } from "helpers/Stakeholders/payer"
import { useFormik } from "formik"
import * as Yup from "yup"
import { isInputInvalid } from "utils"
import { buttonsTitles } from "common/data"
import * as URLs from "helpers/url_helper"

function CardUser({
  setDateRange,
  dateRange,
  handlePayerIdChange,
  setSelectedPayer,
}) {
  const authUser = JSON.parse(localStorage.getItem("authUser"))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      payer_id: "",
    },
    validationSchema: Yup.object({
      // payer_id: generateFieldValidation(inputs, 1002, "dropdown"),
    }),
  })
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row className="justify-content-between">
                <Col lg="4">
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome Back</p>
                        <h5 className="mb-1">{authUser?.name}</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* <Col lg="4" className="d-none d-lg-block">
               
                  <DateRangePicker
                    value={dateRange}
                    setDateRange={setDateRange}
                  />
                </Col> */}
                {/* {location.pathname.includes("/payer-dashboard") && (
                  <Row className="mt-3">
                    <Col md={2}>
                      <SearchSelect
                        name="payer_id"
                        value={validation.values.payer_id}
                        onChange={validation.setFieldValue}
                        onSelectData={(data) => {
                          data ? handlePayerIdChange(data.id) : null;
                        }}
                        onBlur={validation.setFieldTouched}
                        getOptionsData={getAllPayer}
                        urlAddOns={{ is_active: 1 }}
                        mappingLabel="first_name"
                        mappingValue="id"
                        invalid={isInputInvalid(validation, "payer_id")}
                        wrapperClassNames="mb-3"
                      />
                    </Col>
                    <Col
                      md={1}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handlePayerIdChange(validation.values.payer_id)
                        }
                        disabled={!validation.values.payer_id}
                        style={{ width: "100%" }}
                      >
                        {buttonsTitles.submit}
                      </button>
                    </Col>
                  </Row>
                )} */}
                <Col
                  lg={
                    location.pathname.includes("/payer-dashboard") ||
                    location.pathname.includes("/fnol-dashboard")
                      ? 6
                      : 4
                  }
                  className=" d-lg-block me-5"
                >
                  <div
                    className={
                      location.pathname.includes("/payer-dashboard") ||
                      location.pathname.includes("/fnol-dashboard")
                        ? "d-flex align-items-center me-5"
                        : ""
                    }
                  >
                    <Col
                      md={
                        location.pathname.includes("/payer-dashboard") ||
                        location.pathname.includes("/fnol-dashboard")
                          ? 7
                          : null
                      }
                    >
                      <DateRangePicker
                        value={dateRange}
                        setDateRange={setDateRange}
                      />
                    </Col>

                    {(location.pathname.includes("/payer-dashboard") ||
                      location.pathname.includes("/fnol-dashboard")) && (
                      <div className="ms-1 flex-grow-1">
                        <Row className="d-flex align-items-center">
                          <Col md={10}>
                            <AsyncSearchSelect
                              name="payer_id"
                              value={validation.values.payer_id}
                              onChange={validation.setFieldValue}
                              customOnChange={(selected) => {
                                validation.setFieldValue(
                                  "payer_id",
                                  selected ? selected?.id : ""
                                )
                                setSelectedPayer(selected ? selected : "")
                              }}
                              placeholder="Search by name..."
                              onBlur={validation.setFieldTouched}
                              // getOptionsData={getAllPayer}
                              url={URLs.PAYER}
                              mappingLabel="first_name"
                              mappingValue="id"
                              searchKey="first_name"
                              disableFetch={true}
                              invalid={isInputInvalid(validation, "payer_id")}
                              wrapperClassNames="mt-2"
                              fixedMenu={true}
                              // className="mt-3"
                            />
                          </Col>
                          <Col md={2}>
                            <button
                              type="button"
                              className="btn btn-primary mt-3"
                              onClick={() => {
                                handlePayerIdChange(validation.values.payer_id)
                              }}
                              // disabled={!validation.values.payer_id}
                              style={{
                                height: "auto",
                                padding: "8px",
                              }}
                            >
                              {buttonsTitles.submit}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
