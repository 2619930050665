import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const TopBrands = ({ dataColors, KPI }) => {
  // var barChartColor = getChartColorsArray(dataColors)

  const brands =
    KPI.data?.flatMap((item) =>
      item.vehicle_brands?.flatMap((brand) => ({
        name: brand.name,
        count: brand.count,
      }))
    ) || []

  const series = [
    {
      name: "",
      data: brands.length > 0 ? brands.map((brand) => brand.count) : [],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["rgba(52, 195, 143, 0.85)"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: brands.length > 0 ? brands.map((brand) => brand.name) : [],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$ ${val}`
        },
      },
    },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopBrands
