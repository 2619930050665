import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import { Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const PieChart = ({ dataColors, KPI }) => {
  // const PieEChartColors = getChartColorsArray(dataColors)

  // const typeNames = []
  // const typeCounts = []
  // KPI?.data?.forEach((item) => {
  //   item.types.forEach((type) => {
  //     // typeNames.push(type.name)
  //     typeCounts.push(type.count)
  //   })
  // })
  // console.log(typeNames)
  // const data = typeCounts

  // to store unique typenames
  const typeNamesSet = new Set()

  KPI?.data?.forEach((item) => {
    item.types.forEach((type) => {
      typeNamesSet.add(type.name)
    })
  })
  const typeNames = Array.from(typeNamesSet)

  const payerCount = new Set(
    KPI?.data
      .filter((entry) => entry.types.some((type) => type.count > 0))
      .map((entry) => entry.payer.id)
  ).size

  const seriesData = typeNames.map((typeName) => {
    const count = KPI.data.reduce((acc, curr) => {
      const type = curr.types.find((t) => t.name === typeName)
      return type ? acc + type.count : acc
    }, 0)
    return payerCount > 0 ? +(count / payerCount).toFixed(2) : 0
  })

  const options = {
    chart: {
      width: "100px",
      height: "100px",
      type: "pie",
    },
    labels: typeNames,
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{KPI?.name}</h4>
          <div className="mb-5">
            <ReactApexChart
              type="pie"
              height={seriesData && seriesData.length > 0 ? "385px" : "235px"}
              width="100%"
              options={options}
              series={seriesData}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default PieChart
