import { TableContainer } from "components/Common"
import React, { useEffect, useState } from "react"

import { generateColumns } from "utils"
import { getAllPanels } from "helpers/Motors/panels_helper"
import { getAllActions } from "helpers/Permissions/permissions_helper"
import { PanelsModuleId } from "utils/modulesIds"
import { SingleRowAction } from "components/TP/Common/Tables"
import { buttonsTitles } from "common/data"
import { uniqBy } from "lodash"

const InnerPanelsTable = ({
  setRepairs,
  setReplaces,
  parentId,
  panelId,
  setPanelId,
}) => {
  /*
   local states
  */

  // storing the columns that user want to hide
  const [resetInput, setResetInput] = useState(false)
  const [showOptions, setShowOptions] = useState([])
  const [panels, setPanels] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)

  const [actionFileds, setActionFields] = useState([])
  const [isloading, setIsLoading] = useState(false)
  // Header of the Table
  const [columns, setColumns] = useState([])

  const getPanels = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    view,
    parent_id,
    ...rest
  }) => {
    try {
      if (!parent_id) return
      setLoading(true)
      const PanelRes = await getAllPanels({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        view,
        ...rest,
        parent_id: parent_id,
      })
      setPanels(PanelRes?.items)
      setPagination({
        size: PanelRes.size,
        page: PanelRes.page,
        per_page: PanelRes.per_page,
        total_pages: PanelRes.total_pages,
        total: PanelRes.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    if (actionFileds.length > 0) {
      const generatedColumns = generateColumns(actionFileds)
      const filteredColumns = generatedColumns.filter(
        col =>
          col.accessor === "code" ||
          col.accessor === "name" ||
          col.accessor === "secondary_name"
      )

      setColumns([
        {
          Header: "#",
          accessor: "selectedId",
          disableFilters: true,
          disableSortBy: true,
          show: 1,
          Cell: cellprops => {
            const row = cellprops.row.original
            return (
              <input
                type="checkbox"
                className="form-check-input"
                defaultChecked={panelId === row?.id}
                onChange={e => {
                  setPanelId(prev => (prev === row?.id ? null : row?.id))
                }}
              />
            )
          },
        },
        ...filteredColumns,
        {
          Header: buttonsTitles.actions,
          accessor: "actions",
          disableFilters: true,
          Cell: cellProps => {
            // Actions Icons in each row with its props
            const row = cellProps.row.original

            return (
              <div className="d-flex gap-2">
                <SingleRowAction
                  actionName={"Replace"}
                  onClick={() => {
                    setReplaces(prev => uniqBy([...prev, row], "name"))
                  }}
                  iconClass="mdi mdi-autorenew"
                  record={row}
                />
                <SingleRowAction
                  actionName={"Repair"}
                  onClick={() => {
                    setRepairs(prev => uniqBy([...prev, row], "name"))
                  }}
                  iconClass="mdi mdi-hammer-screwdriver"
                  record={row}
                />
              </div>
            )
          },
        },
      ])
    }
  }, [actionFileds.length, panelId])
  //   panels premissions
  const getPanelsPermissions = async () => {
    try {
      setIsLoading(true)
      const res = await getAllActions(PanelsModuleId)
      setActionFields(res?.item?.fields)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    getPanelsPermissions()
  }, [])

  useEffect(() => {
    if (parentId) {
      getPanels({ page: 1, per_page: 10, parent_id: parentId })
    }
  }, [parentId])
  return (
    <div>
      {/* {loading && <TableSkeleton />} */}
      <TableContainer
        columns={columns}
        fullColumns={columns}
        data={panels}
        pagination={pagination}
        getData={getPanels}
        queryAddOn={{
          parent_id: parentId,
        }}
        loading={loading}
        removeActionsButtons={true}
        removeActionsColumns={true}
        resetInput={resetInput}
        setResetInput={setResetInput}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        sheetName={"panel"}
      />
    </div>
  )
}

export default InnerPanelsTable
