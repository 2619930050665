import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const DamageServerity = ({ KPI }) => {
  const typeNamesSet = new Set()

  KPI?.data?.forEach((item) => {
    item.damage_severity?.forEach((type) => {
      typeNamesSet.add(type.name)
    })
  })
  const typeNames = Array.from(typeNamesSet)

  const series = KPI?.data?.flatMap((item) =>
    item.damage_severity?.flatMap((item) => item.count || 0)
  )

  const options = {
    chart: {
      width: "100px",
      height: "100px",
      type: "pie",
    },
    labels: typeNames,
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{KPI?.name}</h4>
          <div className="mb-5">
            <ReactApexChart
              type="pie"
              height="284"
              width="100%"
              options={options}
              series={series}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default DamageServerity
