import { generalPaths } from "common/data/routePaths"
import { TableContainer } from "components/Common"
import {
  activateStaffTask,
  deactivateStaffTask,
  deleteStaffTask,
  getAllStaffTasks,
} from "helpers/General/staff_tasks_helper"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatedTodayData, generateColumns } from "utils"
import AddStaffTask from "./AddStaffTask"
import UpdateStaffTask from "./UpdateStaffTask"
import ViewStaffTask from "./ViewStaffTask"
import moment from "moment"
import { staticLabels } from "common/data"
import parse from "html-react-parser"

const TasksTable = () => {
  const history = useHistory()

  /*
     local states
    */

  // storing the columns that user want to hide
  const [showOptions, setShowOptions] = useState([])
  const [resetInput, setResetInput] = useState(false)
  const [staffTaskToUpdate, setStaffTaskToUpdate] = useState(null)
  const [staffTask, setStaffTask] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const [isAddModal, setIsAddModal] = useState(false)
  // to toggle the updating modal
  const [isUpdateModal, setIsUpdateModal] = useState(false)
  const [isViewModal, setIsViewModal] = useState(false)
  const [showAllTasks, setShowAllTasks] = useState(false)
  const [showassignedBy, setShowAssignedBy] = useState(false)

  const actionFields = useSelector((state) => state?.Module?.actions?.fields)
  const moduleId = useSelector((state) => state?.Module?.actions?.id)
  // Header of the Table
  const [columns, setColumns] = useState([])

  const openAddModal = () => {
    setIsAddModal(true)
  }

  // open updating modal
  const handleClickUpdate = (row) => {
    setStaffTaskToUpdate(row.id)
    setIsUpdateModal(true)
  }

  // open view modal
  const handleClickView = (row) => {
    setStaffTaskToUpdate(row.id)
    setIsViewModal(true)
  }
  const newData = (data) => {
    const color = "#e8b0b0"
    return data.map((task) => ({
      ...task,
      rowClassNames:
        moment(task.due_date).diff(moment(), "days") <= 0 &&
        task.status?.slug !== "taskStatus-done"
          ? "bg-danger bg-opacity-50"
          : "",
      // task.due_date > moment().format("YYYY-MM-DD") &&
      // task.status?.slug !== "taskStatus-done"
      //   ? "bg-danger bg-opacity-50"
      //   : "",

      users: task.users
        ? task.users.filter((user) => user.is_follower === 0)
        : [],
      followers: task.users
        ? task.users.filter((user) => user.is_follower === 1)
        : [],
      counter: task.due_date ? moment(task.due_date).diff(moment(), "days") : 0,
    }))
  }

  // console.log(staffTask)
  useEffect(() => {
    if (actionFields) {
      const generated = generateColumns(actionFields)
      const filteredColumns = generated.map((col) => {
        if (col.Header === "Users") {
          return {
            ...col,
            accessor: "users",
            Cell: ({ cell: { value } }) => {
              const usersData = value
                ? value.filter((user) => user.is_follower === 0)
                : []
              return usersData.map((user) => user.name).join(" ,")
            },
          }
        }
        if (col.Header === "Followers") {
          return {
            ...col,
            accessor: "followers",
            Cell: ({ cell: { value } }) => {
              const followersData = value
                ? value.filter((user) => user.is_follower === 1)
                : []
              return followersData.map((user) => user.name).join(" ,")
            },
          }
        }
        return col
      })
      const newColumn = {
        Header: parse("Days <br/> Count"),
        accessor: "counter",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return value
        },
      }

      filteredColumns.push(newColumn)

      setColumns(filteredColumns)
    }
  }, [actionFields, showOptions])

  const userId = JSON.parse(localStorage.getItem("authUser"))?.id

  const getStaffTasks = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
  }) => {
    try {
      setLoading(true)
      const staffTaskRes = await getAllStaffTasks({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        assigned_user_id: !showAllTasks ? "" : userId,
        created_by_id: showassignedBy ? userId : "",
        // assignedUserId: showassignedBy ? userId : "",
      })
      setStaffTask(newData(staffTaskRes?.items || []))
      setPagination({
        size: staffTaskRes.size,
        page: staffTaskRes.page,
        per_page: staffTaskRes.per_page,
        total_pages: staffTaskRes.total_pages,
        total: staffTaskRes.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    getStaffTasks({
      page: 1,
    })
  }, [showAllTasks, showassignedBy])

  // console.log(JSON.parse(localStorage.getItem("authUser"))?.id)
  return (
    <div>
      <TableContainer
        columns={columns}
        data={staffTask}
        handleAdd={openAddModal}
        pagination={pagination}
        batchPageLink={`${generalPaths.staffTask}/upload`}
        getData={getStaffTasks}
        resetInput={resetInput}
        loading={loading}
        addOnButtonsComponent={
          <div className="d-flex gap-2 align-items-center pt-1">
            <label htmlFor="" className="">
              {staticLabels.assginedToMe}
            </label>
            <div className="square-switch d-flex align-items-center">
              <input
                type="checkbox"
                id="square-switch2"
                switch="info"
                defaultChecked={showAllTasks}
                onChange={(e) => setShowAllTasks((prev) => !prev)}
              />
              <label
                htmlFor="square-switch2"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
            <label htmlFor="" className="">
              {staticLabels.assginedByMe}
            </label>
            <div className="square-switch d-flex align-items-center">
              <input
                type="checkbox"
                id="square-switch3"
                switch="info"
                defaultChecked={showassignedBy}
                onChange={(e) => setShowAssignedBy((prev) => !prev)}
              />
              <label
                htmlFor="square-switch3"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
        }
        setResetInput={setResetInput}
        // for show / hide columns
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        fullColumns={generateColumns(actionFields)}
        sheetName={"Staff Tasks"}
        deleteFunc={deleteStaffTask}
        activateFunc={activateStaffTask}
        deactivateFunc={deactivateStaffTask}
        handleClickUpdate={handleClickUpdate}
        handleClickView={handleClickView}
        moduleId={moduleId}
      />
      <AddStaffTask
        isAddModal={isAddModal}
        setIsAddModal={setIsAddModal}
        refetchList={() => {
          getStaffTasks({ page: 1, per_page: 10 })
          setResetInput(true)
        }}
      />
      {isUpdateModal && (
        <UpdateStaffTask
          isUpdateModal={isUpdateModal}
          setIsUpdateModal={setIsUpdateModal}
          staffTaskToUpdate={staffTaskToUpdate}
          setStaffTaskToUpdate={setStaffTaskToUpdate}
          refetchList={() => {
            getStaffTasks({ page: 1, per_page: 10 })
            setResetInput(true)
          }}
        />
      )}
      {isViewModal && (
        <ViewStaffTask
          isViewModal={isViewModal}
          setIsViewModal={setIsViewModal}
          staffTaskToUpdate={staffTaskToUpdate}
          setStaffTaskToUpdate={setStaffTaskToUpdate}
        />
      )}
    </div>
  )
}

export default TasksTable
