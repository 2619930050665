import React, { Fragment, useState } from "react"
import MultiSkeleton from "../Common/MultiSkeleton"
import moment from "moment"
import { StatusHistoryModal } from "."
import { modalsStates } from "common/data"
import ViewClaimProviderModal from "./ViewClaimProviderModal"

const TopBeneficiaryDetails = ({
  beneficiary,
  isLoading,
  claimStatus,
  reference,
  provider,
  contractId,
  benefit,
  claim,
  isReimbursement,
}) => {
  const [showModal, setShowModal] = useState("")
  return (
    <Fragment>
      <div className="mb-2">
        {isLoading ? (
          <MultiSkeleton length={1} />
        ) : (
          <ul className="list-unstyled hstack gap-2 flex-wrap">
            {/* Claims Priority */}
            {claim && claim?.priority?.slug === "priority-high" ? (
              <li className="d-flex gap-2 align-items-center text-danger text-capitalize">
                <i className="mdi mdi-alert font-size-14 "></i>
                <span>{claim?.priority?.name}</span>
              </li>
            ) : null}

            {/* Status and reference */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-shield-quarter font-size-14 text-primary"></i>
              {/* in update make it a hyber link to open statuses history popup */}
              {/* in add just a text*/}
              {reference ? (
                <button
                  type="button"
                  className="btn bg-transparent border-0 p-0 text-blue"
                  onClick={() => setShowModal(modalsStates.statusHistory)}
                >
                  {isReimbursement ? "Reimb/" : ""} {benefit?.slug} :{" "}
                  {reference} {`{${claimStatus?.name}}`}
                </button>
              ) : (
                <span>
                  {isReimbursement ? "Reimb/" : ""} {benefit?.slug} :
                  {`{${claimStatus?.name}}`}
                </span>
              )}
            </li>
            {/* Provider Name */}
            {provider ? (
              <li
                className="d-flex gap-2 align-items-center"
                data-toggle="tooltip"
                title={provider?.first_name}
                onClick={() => setShowModal(modalsStates.view)}
              >
                <i className="bx bx-buildings font-size-14 text-primary"></i>
                <span>{provider?.first_name?.slice(0, 25)}</span>
              </li>
            ) : null}
            {/* Beneficiary Code */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-id-card font-size-14 text-primary"></i>
              <span>{beneficiary?.code}</span>
            </li>
            {/* Data of Birth */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-cake font-size-14 text-primary"></i>
              {beneficiary && beneficiary.birth_date ? (
                <Fragment>
                  {/* gender */}
                  <span className="fw-bold">
                    {beneficiary?.gender?.name?.slice(0, 1)?.toUpperCase()}
                  </span>
                  /{""}
                  {/* birth date */}
                  <span>
                    {beneficiary?.birth_date}
                    {` (${moment().diff(beneficiary.birth_date, "years")} Y)`}
                  </span>
                </Fragment>
              ) : (
                <span>N/A</span>
              )}
            </li>
            {/* Policy Start Date to Policy End Date */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-calendar font-size-14 text-primary"></i>
              <span>
                <b>From</b>{" "}
                {beneficiary?.policy?.start_date
                  ? moment(beneficiary?.policy?.start_date).format("DD-MM-YYYY")
                  : "N/A"}{" "}
                <b>To</b>{" "}
                {beneficiary?.policy?.stop_date
                  ? moment(beneficiary?.policy?.stop_date).format("DD-MM-YYYY")
                  : "N/A"}
              </span>
            </li>
            {/* Coparticipations Plans */}
            <li className="d-flex gap-2 align-items-center fw-bold">
              <i className="bx bx-money font-size-14 text-primary"></i>
              <span>
                Plans:{" "}
                {beneficiary &&
                beneficiary.co_participations &&
                beneficiary.co_participations.length > 0 ? (
                  beneficiary.co_participations.map((item, index) => (
                    <span key={item.id} className="font-size-12">
                      {item.name}
                      {index === beneficiary?.co_participations?.length - 1
                        ? ""
                        : " | "}
                    </span>
                  ))
                ) : (
                  <span>N/A</span>
                )}
              </span>
            </li>
          </ul>
        )}
      </div>
      {showModal === modalsStates.statusHistory ? (
        <StatusHistoryModal
          show={showModal === modalsStates.statusHistory}
          statuses={claim?.statuses}
          onCloseClick={() => setShowModal("")}
          loading={false}
        />
      ) : null}
      {showModal === modalsStates.view ? (
        <ViewClaimProviderModal
          isModal={showModal === modalsStates.view ? true : false}
          setIsModal={setShowModal}
          providerId={provider?.id}
          contractId={contractId}
          modalTitle={`View ${provider?.first_name} Details`}
        />
      ) : null}
    </Fragment>
  )
}

export default TopBeneficiaryDetails
