import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useDispatch } from "react-redux"
import { getSubmodules, getActions as onGetActions } from "store/actions"
import { staffTasksModuleID } from "utils/modulesIds"
import TasksTable from "./TasksTable"

const staffTasks = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetActions(staffTasksModuleID))
  }, [onGetActions])

  useEffect(() => {
    dispatch(getSubmodules(staffTasksModuleID))
  }, [onGetActions])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <PageBreadCrumbAndTitle />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            <Col xs="12">
              {/* Card To Ouptut White Card Around The Table */}
              <Card>
                <CardBody>
                  {/* PCIRules Table Component */}
                  <TasksTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default staffTasks
