import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

import { showCompany } from "helpers/Settings/Company"
import { NotificationMessage, apiErrorrHandler, encryptText } from "utils"
import CarouselPage from "./CarouselPage"

const Login = props => {
  //meta title
  document.title = "Login"

  const dispatch = useDispatch()
  // states

  const [passwordShow, setPasswordShow] = useState(false)

  // Calling for view company endpoint
  // to get the currency from it after login
  const getCompany = async () => {
    try {
      const response = await showCompany()
      const mainCurrency = response?.item?.main_currency
      localStorage.setItem(
        "TPACurrency",
        encryptText(JSON.stringify(mainCurrency))
      )
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
      remember_me: false,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      remember_me: Yup.boolean(),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history, () => getCompany()))
    },
  })

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = validation

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">Sign in to continue.</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            handleSubmit()
                            return false
                          }}
                        >
                          {/* Error Message */}
                          {error ? (
                            <Alert color="danger">
                              {typeof error === "object" && error !== null
                                ? "Incorrect Email or Password"
                                : error}
                            </Alert>
                          ) : null}
                          {/* Email Input */}
                          <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter Username"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.username || ""}
                              invalid={
                                touched.username && errors.username
                                  ? true
                                  : false
                              }
                            />
                            {touched.username && errors.username ? (
                              <FormFeedback type="invalid">
                                {errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          {/* password */}
                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.password && errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                            {touched.password && errors.password ? (
                              <FormFeedback type="invalid">
                                {errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                              disabled={loading}
                            >
                              Log In
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p>© {new Date().getFullYear()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Login))

Login.propTypes = {
  history: PropTypes.object,
}
