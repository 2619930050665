import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const ProblemType = ({ KPI }) => {
  const typeNamesSet = new Set()

  const ProblemsTypes =
    KPI.data?.flatMap((item) =>
      item.problems?.flatMap((problem) => ({
        name: problem.name,
        count: problem.count,
      }))
    ) || []
  const typeNames = Array.from(typeNamesSet)

  // const series = KPI?.data?.flatMap((item) =>
  //   item.damage_severity?.flatMap((item) => item.calculated_value || 0)
  // )
  const series = [
    {
      name: "",
      data:
        ProblemsTypes.length > 0
          ? ProblemsTypes.map((problem) => problem.count)
          : [],
    },
  ]

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: "80%",
        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
      },
      dropShadow: {
        enabled: true,
      },
    },
    title: {
      text: "",
      align: "middle",
    },
    xaxis: {
      categories:
        ProblemsTypes.length > 0
          ? ProblemsTypes.map((problem) => problem.name)
          : [],
    },
    legend: {
      show: false,
    },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{KPI?.name}</h4>
          <div className="mb-5">
            <ReactApexChart
              type="bar"
              height="284"
              width="100%"
              options={options}
              series={series}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProblemType
