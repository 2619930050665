import { get, request } from "../api_helper"

import * as url from "../url_helper"

export const getALLSpareParts = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.MOTORSPAREPARTS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// add MOTORSPAREPARTS

export const addMotorSparePart = (data) =>
  request({ url: url.MOTORSPAREPARTS, method: "POST", data })

// show MOTORSPAREPARTS

export const showMotorSparePart = (SparePartId) =>
  request({ url: `${url.MOTORSPAREPARTS}/${SparePartId}`, method: "GET" })

// update MOTORSPAREPARTS
//url/{id}

export const updateMotorSparePart = (data) =>
  request({ url: `${url.MOTORSPAREPARTS}/${data.id}`, method: "PUT", data })

// delete MOTORSPAREPARTS
//url/{id}
export const deleteMotorSparePart = (SparePartId) =>
  request({
    url: `${url.MOTORSPAREPARTS}/${SparePartId}`,
    method: "DELETE",
  })

// activate MOTORSPAREPARTS
//url/activate/{id}
export const activateMotorSparePart = (SparePartId) =>
  request({
    url: `${url.MOTORSPAREPARTS}/${SparePartId}/activate`,
    method: "POST",
  })

// deactivate MOTORSPAREPARTS
//url/deactivate/{id}
export const deactivateMotorSparePart = (SparePartId) =>
  request({
    url: `${url.MOTORSPAREPARTS}/${SparePartId}/deactivate`,
    method: "POST",
  })
