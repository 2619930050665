import PropTypes from "prop-types"
import React from "react"
import { Modal } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { buttonsTitles } from "common/data"

const SaveConfirmationModal = ({
  show,
  onCloseClick,
  onConfirmClick,
  isSaving,
  modalTitle,
  hideSaveButton,
  confirmationMessage,
  t,
}) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      {/* Modal Header */}

      <div className="modal-header">
        {/* Modal Header Title */}

        <h5 className="modal-title mt-0" id="myModalLabel">
          {modalTitle}
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <p className="text-muted font-size-16 mb-4 text-left">
          {t(confirmationMessage)}
        </p>
      </div>

      {/* Modal Footer */}
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-center mb-0">
          <button
            type="button"
            className="btn btn-light"
            onClick={onCloseClick}
          >
            {buttonsTitles.close}
          </button>
          {!hideSaveButton && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConfirmClick}
              disabled={isSaving}
            >
              {isSaving && (
                <i className="bx bx-loader bx-spin font-size-14 align-middle me-1"></i>
              )}
              {buttonsTitles.confirm}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

SaveConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  isActivating: PropTypes.bool,
}
export default withTranslation()(SaveConfirmationModal)
