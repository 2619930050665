import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { Alert, Col, Row } from 'reactstrap';
import {
  DatePickerInput,
  FormControll,
  FormModal,
  ModalSpinner,
  SearchSelect,
} from 'components/TP/Common/Forms';
import * as Yup from 'yup';
import * as URLs from 'helpers/url_helper';
import {
  apiErrorrHandler,
  convertNullToString,
  fieldFinder,
  generateFieldValidation,
  isInputInvalid,
  NotificationMessage,
} from 'utils';
import { USER_MODULES, STAFFTASKS } from 'helpers/url_helper';
import { staffTasksModuleID } from 'utils/modulesIds';
import useSWR from 'swr';
import { fetcher } from 'utils/API/SWRFetcher';
import { useSelector } from 'react-redux';
import {
  updateStaffTask,
  showStaffTask,
} from '../../../../../helpers/General/staff_tasks_helper';
import DropZone from 'components/TP/Common/DropZone';
import { UploadFiles } from 'utils/API/UploadFiles';

function EditSourceTask({
  id,
  setShow,
  show,
  isView,
  setIsView,
  refetchList,
  sourceType,
}) {
  // current module name
  const { moduleName } = useSelector((state) => ({
    moduleName: state.Module?.name,
  }));

  const url = `${USER_MODULES}/${staffTasksModuleID}`;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [task, setTask] = useState(null);
  const [error, setError] = useState('');
  const [taskLoading, setTaskLoading] = useState(false);

  // get fields
  const {
    isLoading,
    error: fieldError,
    data,
  } = useSWR(
    () => (url ? [url] : null),
    ([url]) => fetcher(url),
    {
      revalidateOnFocus: false,
    }
  );

  // get task
  // const staffUrl = `${STAFFTASKS}/${id}`;
  // const {
  //   isLoading: taskLoading,
  //   error: taskError,
  //   data: task,
  // } = useSWR(
  //   () => (staffUrl ? [staffUrl] : null),
  //   ([staffUrl]) => fetcher(staffUrl),
  //   {
  //     revalidateOnFocus: false,
  //   }
  // );

  const inputs = data?.fields;

  const getTask = async (id) => {
    setTaskLoading(true);
    try {
      const response = await showStaffTask(id);
      setTask(convertNullToString(response.item));
      setSelectedFiles(
        (response?.item?.files?.map((item) => item?.files) || []).filter(
          (id) => id !== null && id !== undefined
        )
      );
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage('Error', errorMessage);
    } finally {
      setTaskLoading(false);
    }
  };

  const handleUpdateTask = async (values, setSubmitting, resetForm) => {
    let body;
    try {
      if (sourceType === 'Claim') {
        const fileIds = await UploadFiles(selectedFiles);
        body = {
          id: id,
          ...values,
          files: fileIds,
          users: [
            ...values.users?.map((user) => ({
              user_id: user,
              is_follower: 0,
            })),

            ...values.followers?.map((user) => ({
              user_id: user,
              is_follower: 1,
            })),
          ],
        };
      } else {
        body = {
          id: id,
          ...values,
          // files: fileIds,
          users: [
            ...values.users?.map((user) => ({
              user_id: user,
              is_follower: 0,
            })),

            ...values.followers?.map((user) => ({
              user_id: user,
              is_follower: 1,
            })),
          ],
        };
      }

      await updateStaffTask({
        ...body,
      });
      NotificationMessage('success', 'add');
      resetForm();
      setShow(false);
      refetchList();
      setError('');
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (id) {
      getTask(id);
    }
  }, [id]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: task ? task?.title : '',
      description: task ? task?.description : '',
      status_id: task ? task?.status?.id : '',
      priority_id: task ? task?.priority?.id : '',
      module_id: task ? task?.module?.id : '',
      usage_id: task ? task?.usage?.id : '',
      start_date: task ? task?.start_date : '',
      due_date: task ? task?.due_date : '',
      users:
        task && task?.users
          ? task?.users
              .filter((item) => item.is_follower === 0)
              .map((item) => item.id)
          : [],
      followers:
        task && task?.users
          ? task?.users
              .filter((item) => item.is_follower === 1)
              .map((item) => item.id)
          : [],
    },
    validationSchema: Yup.object({
      title: generateFieldValidation(inputs, 1570, 'string'),
      description: generateFieldValidation(inputs, 1571, 'string'),
      status_id: generateFieldValidation(inputs, 1572, 'dropdown'),
      priority_id: generateFieldValidation(inputs, 1573, 'dropdown'),
      start_date: generateFieldValidation(inputs, 1574, 'date'),
      due_date: generateFieldValidation(inputs, 1575, 'date'),
      users: generateFieldValidation(inputs, 1576, 'array'),
      followers: generateFieldValidation(inputs, 1577, 'array'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleUpdateTask(values, setSubmitting, resetForm);
    },
  });

  // console.log(moment('yy-mm-dd'));

  return (
    <FormModal
      modalSize="md"
      show={show}
      onCloseClick={() => {
        setShow(false);
        validation.resetForm();
        setError('');
      }}
      onSaveClick={validation.handleSubmit}
      modalTitle={`${moduleName} - ${isView ? 'View Task' : 'Edit Task'}`}
      isSaving={validation.isSubmitting}
      hideSaveButton={isView ? true : false}
    >
      {isLoading || taskLoading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          {error ? <Alert color="danger">{error}</Alert> : null}

          {inputs && inputs.length > 0 && (
            <>
              <Row>
                {/* <Col md={3}> */}
                <FormControll
                  id="1570"
                  name="title"
                  type="text"
                  inputField={fieldFinder(inputs, 1570)}
                  {...validation.getFieldProps('title')}
                  invalid={isInputInvalid(validation, 'title')}
                  error={validation.errors.title}
                  wrapperClassNames="mb-3"
                  readOnly={isView ? true : false}
                />
                {/* </Col> */}
                <Col md={6}>
                  <DatePickerInput
                    id="1574"
                    name="start_date"
                    // minDate={moment().format('YYYY-MM-DD')}
                    inputField={fieldFinder(inputs, 1574)}
                    value={validation.values.start_date}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    invalid={isInputInvalid(validation, 'start_date')}
                    error={validation.errors.start_date}
                    wrapperClassNames="mb-4"
                    // disablePastDates={true}
                    readOnly={isView ? true : false}
                  />
                </Col>
                <Col md={6}>
                  <DatePickerInput
                    id="1575"
                    name="due_date"
                    inputField={fieldFinder(inputs, 1575)}
                    value={validation.values.due_date}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    invalid={isInputInvalid(validation, 'due_date')}
                    error={validation.errors.due_date}
                    wrapperClassNames="mb-4"
                    // disablePastDates={true}
                    // minDate={validation.values.start_date}
                    readOnly={isView ? true : false}
                  />
                </Col>
                <Col md={6}>
                  <SearchSelect
                    id="1573"
                    name="priority_id"
                    inputField={fieldFinder(inputs, 1573)}
                    value={validation.values.priority_id || null}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    mappingLabel="name"
                    mappingValue="id"
                    url={`${URLs.PREDEFINEDS}/${
                      fieldFinder(inputs, 1573)?.predefined_value?.id
                    }`}
                    invalid={isInputInvalid(validation, 'priority_id')}
                    error={validation.errors.priority_id}
                    wrapperClassNames="mb-3"
                    readOnly={isView ? true : false}
                  />
                </Col>
                <Row>
                  <Col md={6}>
                    <SearchSelect
                      id="1576"
                      name="users"
                      inputField={fieldFinder(inputs, 1576)}
                      value={validation.values.users || null}
                      onChange={validation.setFieldValue}
                      onBlur={validation.setFieldTouched}
                      mappingLabel="name"
                      mappingValue="id"
                      url={URLs.USERS}
                      disableSorting={true}
                      invalid={isInputInvalid(validation, 'users')}
                      error={validation.errors.users}
                      wrapperClassNames="mb-3"
                      multiple={true}
                      readOnly={isView ? true : false}
                    />
                  </Col>
                  <Col md={6}>
                    <SearchSelect
                      id="1577"
                      name="followers"
                      inputField={fieldFinder(inputs, 1577)}
                      value={validation.values.followers || null}
                      onChange={validation.setFieldValue}
                      onBlur={validation.setFieldTouched}
                      mappingLabel="first_name"
                      mappingValue="id"
                      url={URLs.USERS}
                      invalid={isInputInvalid(validation, 'followers')}
                      error={validation.errors.followers}
                      wrapperClassNames="mb-3"
                      multiple={true}
                      readOnly={isView ? true : false}
                    />
                  </Col>
                </Row>
                <FormControll
                  id="1571"
                  name="description"
                  type="textarea"
                  inputField={fieldFinder(inputs, 1571)}
                  {...validation.getFieldProps('description')}
                  invalid={isInputInvalid(validation, 'description')}
                  error={validation.errors.description}
                  wrapperClassNames="mb-3"
                  readOnly={isView ? true : false}
                />
              </Row>
            </>
          )}

          {sourceType === 'Claim' && (
            <DropZone
              selectedFiles={selectedFiles}
              setselectedFiles={setSelectedFiles}
            />
          )}
        </form>
      )}
    </FormModal>
  );
}

export default EditSourceTask;
