import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import { Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const ActiveMembers = ({ dataColors, KPI }) => {
  // const PieEChartColors = getChartColorsArray(dataColors)

  // console.log(KPI)
  // const classNames = []
  // const typeCounts = []

  // KPI?.data?.forEach((item) => {
  //   item.classes.forEach((clas) => {
  //     classNames.push(clas.name)
  //     typeCounts.push(clas.count)
  //   })
  // })

  // console.log(typeCounts)
  // console.log(classNames)
  // const data = typeCounts

  const classNamesSet = new Set()
  KPI?.data?.forEach((item) => {
    item.classes.forEach((clas) => {
      classNamesSet.add(clas.name)
    })
  })

  const payerCount = new Set(
    KPI?.data
      .filter((entry) => entry.classes.some((clas) => clas.count > 0))
      .map((entry) => entry.payer.id)
  ).size

  const classNames = Array.from(classNamesSet)
  const seriesData = classNames.map((className) => {
    const count = KPI.data.reduce((acc, curr) => {
      const clas = curr.classes.find((c) => c.name === className)
      return clas ? acc + clas.count : acc
    }, 0)
    return payerCount > 0 ? +(count / payerCount).toFixed(2) : 0
  })

  const options = {
    chart: {
      width: "100px",
      height: "100px",
      type: "pie",
    },
    labels: classNames,
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{KPI?.name}</h4>
          <div className="mb-5">
            <ReactApexChart
              type="pie"
              height={seriesData && seriesData.length > 0 ? "385px" : "235px"}
              width="100%"
              options={options}
              series={seriesData}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ActiveMembers
