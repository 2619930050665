import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const TopRegions = ({ dataColors, KPI }) => {
  // var barChartColor = getChartColorsArray(dataColors)

  // console.log(KPI?.data)
  const accidentLocations =
    KPI.data?.flatMap((item) =>
      item.regions?.flatMap((loc) => ({
        name: loc.name,
        count: loc.count,
      }))
    ) || []

  // const regionNames = new Set(
  //   accidentLocations?.flatMap((loc) => loc.regions.map((r) => r.name))
  // )

  // const uniqueRegionNamesArray = Array.from(regionNames)

  const series = [
    {
      name: "",
      data:
        accidentLocations.length > 0
          ? accidentLocations.map((loc) => loc.count)
          : [],
      // [66, 20, 30, 40, 50, 48, 255, 14, 5],
      // accidentLocations.length > 0
      //   ? accidentLocations.map((loc) => loc.location)
      //   : [],
    },
  ]

  // console.log(series)

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#00BDFF"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories:
        accidentLocations.length > 0
          ? accidentLocations.map((loc) => loc.name)
          : [],
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return `$ ${val}`
    //     },
    //   },
    // },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopRegions
