import { get, request } from "../api_helper"

import * as url from "../url_helper"

export const getAllPanels = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.PANELS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// add Panel

export const addPanel = (data) =>
  request({ url: url.PANELS, method: "POST", data })

// show Panel

export const showPanel = (panelId) =>
  request({ url: `${url.PANELS}/${panelId}`, method: "GET" })

// update Panel
//url/{id}

export const updatePanel = (data) =>
  request({ url: `${url.PANELS}/${data.id}`, method: "PUT", data })

// delete Panel
//url/{id}
export const deletePanel = (panelId) =>
  request({
    url: `${url.PANELS}/${panelId}`,
    method: "DELETE",
  })

// activate Panel
//url/activate/{id}
export const activatePanel = (panelId) =>
  request({
    url: `${url.PANELS}/${panelId}/activate`,
    method: "POST",
  })

// deactivate Panel
//url/deactivate/{id}
export const deactivatePanel = (panelId) =>
  request({
    url: `${url.PANELS}/${panelId}/deactivate`,
    method: "POST",
  })
