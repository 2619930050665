import PropTypes from "prop-types"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { Col, Container, Row, Card, CardBody } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import { dashboardsID, payerDashboardID } from "utils/modulesIds"
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import { CardUser, GaugeChart, PieChart } from "components/TP/dashboards"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { useSelector } from "react-redux"
import { APIDateFormat } from "constants/TPA"
import moment from "moment"
import dayjs from "dayjs"
import ProviderChart from "components/TP/dashboards/ProviderChart"
import { showDashboard, showKPI } from "helpers/General/dashboards_helper"
import MedicalCodeChart from "components/TP/dashboards/MedicalCodeChart"
import ClaimsAmount from "components/TP/dashboards/CliamsAmount"
import { ModalSpinner } from "components/TP/Common/Forms"
import LossRatio from "components/TP/dashboards/LossRatio"
import ActiveMembers from "components/TP/dashboards/ActiveMembers"
import LineColumnApexChart from "components/TP/Charts/LineColumnApexChart"
import LineApexChart from "components/TP/Charts/LineApexChart"
import ReactApexChart from "react-apexcharts"
import ClaimOverview from "components/TP/dashboards/ClaimOverview"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import BusinessOverView from "components/TP/dashboards/BusinessOverView"

const PayerDashboard = (props) => {
  const { module } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === payerDashboardID),
    loading: state.Sidebar?.loading,
  }))

  const dataColors =
    '["--bs-primary", "--bs-success", "--bs-danger" , "--bs-secondary"]'

  const [dashboardKPIs, setDashboardKPIs] = useState([])
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat).replace(/\//g, "-"),
    end_date: dayjs().format(APIDateFormat).replace(/\//g, "-"),
  })
  const [isLoading, setIsLoading] = useState(false)
  const [loadingSlug, setLoadingSlug] = useState("")
  const [selectedPayerId, setSelectedPayerId] = useState(null)
  const [selectedPayer, setSelectedPayer] = useState(null)
  const [kpiResponses, setKpiResponses] = useState({})
  const [slugs, setSlugs] = useState([])

  //call dashboard to get all slugs
  const getDashboard = async (dashboardId, dates) => {
    setIsLoading(true)
    try {
      const response = await showDashboard(dashboardId, dates)
      setDashboardKPIs(response.item)
      setSlugs(response.item.map((dashboardItem) => dashboardItem.slug))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  //call kpi endpoint to get data of one slug
  const getKPI = async (slug, data) => {
    setLoadingSlug(`${slug}`)
    try {
      const response = await showKPI(slug, {
        start_date: moment(dateRange.start_date)
          .format(APIDateFormat)
          .replace(/\//g, "-"),
        end_date: moment(dateRange.end_date)
          .format(APIDateFormat)
          .replace(/\//g, "-"),
        ...data,
      })
      setKpiResponses((prevKpiResponses) => ({
        ...prevKpiResponses,
        [slug]: response.item,
      }))
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setLoadingSlug("")
    }
  }

  useEffect(() => {
    getDashboard(payerDashboardID, {
      start_date: moment(dateRange.start_date)
        .format(APIDateFormat)
        .replace(/\//g, "-"),
      end_date: moment(dateRange.end_date)
        .format(APIDateFormat)
        .replace(/\//g, "-"),
    })
  }, [])

  //to send payer id value
  const handlePayerIdChange = (value) => {
    setSelectedPayerId(value)
    getPayerKPI()
  }
  useEffect(() => {
    if (selectedPayerId && slugs.length > 0) {
      handlePayerIdChange()
      getPayerKPI()
    }
  }, [slugs, selectedPayerId])

  // get kpi of each slug
  const getPayerKPI = () => {
    slugs.forEach((slug) => {
      let conditionKeys = []
      let conditionValues = []

      switch (slug) {
        case "active-policies-count-by-type-and-payer":
        case "loss-ratio-by-policy-type-and-payer":
        case "count-active-beneficiary-by-class":
        case "gender-count-by-policy-date-range":
          conditionKeys = selectedPayerId ? ["payer_id"] : []
          conditionValues = selectedPayerId ? [selectedPayerId] : []
          break
        case "claim-top-providers-by-amount-and-payer":
        case "claim-top-general-assessment-by-amount-and-payer":
        case "claim-total-amount-by-benefit-and-payer":
        case "retention-ratio-by-payer":
        case "claims-amounts-by-months-and-fob":
          conditionKeys = selectedPayerId ? ["policy.payer_id"] : []
          conditionValues = selectedPayerId ? [selectedPayerId] : []
          break
        case "business-overview-per-policies-date-status-premiums":
          conditionKeys = []
          conditionValues = []
        default:
          null
          break
      }

      getKPI(slug, {
        condition_keys: conditionKeys,
        condition_values: conditionValues,
        ...(slug === "gender-count-by-policy-date-range" && {
          compare_start_date: moment(dateRange.start_date)
            .subtract(1, "year")
            .format(APIDateFormat)
            .replace(/\//g, "-"),
          compare_end_date: moment(dateRange.start_date)
            .format(APIDateFormat)
            .replace(/\//g, "-"),
        }),
      })
    })
  }

  //function to render each compnent
  const renderChart = (size, kpiKey, ChartComponent) => (
    <Col md={size}>
      {loadingSlug === kpiKey ? (
        <MultiSkeleton length={8} />
      ) : (
        <Fragment>
          {kpiResponses[kpiKey] && (
            <ChartComponent
              KPI={kpiResponses[kpiKey]}
              key={kpiKey}
              dataColors={dataColors}
            />
          )}
        </Fragment>
      )}
    </Col>
  )

  // refetch the claim by FOB
  // every 30 minutes
  const ref = useRef(null)

  const refetch = () => {
    getKPI("claim-total-amount-by-benefit-and-payer", {
      condition_keys: ["policy.payer_id"],
      condition_values: [selectedPayer?.id],
    })
  }

  useEffect(() => {
    if (selectedPayer) {
      ref.current = setInterval(refetch, 30 * 60 * 1000)
    }
    return () => {
      if (ref.current) {
        clearInterval(ref.current)
      }
    }
  }, [JSON.stringify(selectedPayer)])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {/* Welcome User Card */}
            <CardUser
              setDateRange={setDateRange}
              dateRange={dateRange}
              handlePayerIdChange={handlePayerIdChange}
              setSelectedPayer={setSelectedPayer}
            />
            {/* Loading State */}
            {isLoading ? <ModalSpinner color="primary" /> : null}
          </Row>

          <Row>
            {renderChart(12, "loss-ratio-by-policy-type-and-payer", LossRatio)}
          </Row>

          <Row className="mb-3">
            {loadingSlug === "loss-ratio-by-policy-type-and-payer" ? (
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <MultiSkeleton length={8} />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              renderChart(
                6,
                "business-overview-per-policies-date-status-premiums",
                BusinessOverView
              )
            )}

            <Col lg={6}>
              <Card>
                {loadingSlug === "loss-ratio-by-policy-type-and-payer" ? (
                  <CardBody>
                    <MultiSkeleton length={8} />
                  </CardBody>
                ) : (
                  <Fragment>
                    {kpiResponses["loss-ratio-by-policy-type-and-payer"] && (
                      <CardBody>
                        <div className="mb-4 card-title">Members Overview</div>
                        <LineApexChart dataColors='["--bs-blue", "--bs-green"]' />
                      </CardBody>
                    )}
                  </Fragment>
                )}
              </Card>
            </Col>
            {renderChart(6, "claims-amounts-by-months-and-fob", ClaimOverview)}
            {renderChart(
              6,
              "active-policies-count-by-type-and-payer",
              PieChart
            )}
            {/* {renderChart(4, "retention-ratio-by-payer", GaugeChart)}
             */}
            {renderChart(6, "count-active-beneficiary-by-class", ActiveMembers)}

            {renderChart(
              6,
              "claim-total-amount-by-benefit-and-payer",
              ClaimsAmount
            )}

            {renderChart(
              6,
              "claim-top-providers-by-amount-and-payer",
              ProviderChart
            )}

            {renderChart(
              6,
              "claim-top-general-assessment-by-amount-and-payer",
              MedicalCodeChart
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PayerDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PayerDashboard)
