// import getChartColorsArray from "components/Common/ChartsDynamicColor"
// import React from "react"
// import ReactApexChart from "react-apexcharts"
// import { Card, CardBody } from "reactstrap"

// const AccidentByTime = ({ dataColors, KPI }) => {
//   const apexcardUserColors = getChartColorsArray(dataColors)

//   const AccidentTimes =
//     KPI.data?.flatMap((item) =>
//       item.time_intervals?.map((time) => {
//         const [start, end] = time.time_interval?.split(" - ").map((t) => {
//           const [hour, minute] = t.split(":").map(Number)
//           return hour + minute / 60
//         })
//         return {
//           time_interval: time.time_interval,
//           start,
//           end,
//           count: time.count,
//         }
//       })
//     ) || []

//   const series = [
//     {
//       name: "Accidents",
//       data: AccidentTimes.map((time) => ({
//         x: time.time_interval,
//         y: [time.start, time.end],
//         count: time.count,
//       })),
//     },
//   ]

//   const options = {
//     chart: {
//       height: 390,
//       type: "rangeBar",
//       zoom: {
//         enabled: false,
//       },
//       toolbar: {
//         show: false,
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: true,
//         barHeight: "50%",
//       },
//     },

//     xaxis: {
//       type: "numeric",
//       title: {
//         text: "Time (Hours)",
//       },
//       labels: {
//         formatter: function (val) {
//           const hours = Math.floor(val)
//           const minutes = Math.floor((val % 1) * 60)
//           return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`
//         },
//       },
//     },
//     yaxis: {
//       labels: {
//         show: true,
//       },
//     },
//     tooltip: {
//       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
//         return `<div class="arrow_box">
//           <span>${AccidentTimes[dataPointIndex].count} accidents</span>
//         </div>`
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       formatter: function (val, { dataPointIndex }) {
//         return AccidentTimes[dataPointIndex].count.toString()
//       },
//       style: {
//         colors: ["#fff"],
//       },
//     },
//   }

//   return (
//     <Card>
//       <CardBody>
//         <div className="d-flex flex-wrap">
//           <h5 className="card-title me-2">{KPI?.name}</h5>
//         </div>
//         <ReactApexChart
//           options={options}
//           series={series}
//           type="rangeBar"
//           height="390"
//         />
//       </CardBody>
//     </Card>
//   )
// }

// export default AccidentByTime
import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const AccidentByTime = ({ dataColors, KPI }) => {
  const apexcardUserColors = getChartColorsArray(dataColors)

  const AccidentTimes =
    KPI.data?.flatMap((item) =>
      item.time_intervals?.map((time) => ({
        time_interval: time.time_interval,
        count: time.count,
      }))
    ) || []
  const filteredAccidentTimes = AccidentTimes.filter(
    (time) => time.time_interval != null && time.count != null
  )

  const series = filteredAccidentTimes.map((time) => time.count)
  const labels = filteredAccidentTimes.map((time) => time.time_interval)
  const options = {
    chart: {
      width: "100px",
      height: "390px",
      type: "pie",
    },
    labels: labels,
    legend: {
      position: "bottom",
    },
    tooltip: {
      custom: function ({ series, seriesIndex, w }) {
        return `<div class="arrow_box">
          <span>${series[seriesIndex]} accidents</span>
        </div>`
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { seriesIndex }) {
        return `${series[seriesIndex]} `
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],

    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  }

  return (
    <Card>
      <CardBody>
        <div className="d-flex flex-wrap">
          <h5 className="card-title me-2">{KPI?.name}</h5>
        </div>
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          height="390"
          width="100%"
        />
      </CardBody>
    </Card>
  )
}

export default AccidentByTime
