import { TableContainer } from "components/Common"
import React, { useEffect, useState } from "react"

import { generateColumns } from "utils"
import { getAllActions } from "helpers/Permissions/permissions_helper"
import { MotorSparePartsModuleId } from "utils/modulesIds"
import { SingleRowAction } from "components/TP/Common/Tables"
import { buttonsTitles } from "common/data"
import { getALLSpareParts } from "helpers/Motors/spare_parts_helper"
import { uniqBy } from "lodash"

const InnerSparePartsTable = ({ setRepairs, setReplaces, panelId }) => {
  /*
   local states
  */

  // storing the columns that user want to hide
  const [resetInput, setResetInput] = useState(false)
  const [showOptions, setShowOptions] = useState([])
  const [spareParts, setSpareParts] = useState([])

  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)

  const [isloading, setIsLoading] = useState(false)
  // Header of the Table
  const [columns, setColumns] = useState([])

  const getSpareParts = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    panel_id,
  }) => {
    try {
      if (!panel_id) return
      setLoading(true)
      const MotorSparePartRes = await getALLSpareParts({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        panel_id,
      })
      setSpareParts(MotorSparePartRes?.items)
      setPagination({
        size: MotorSparePartRes.size,
        page: MotorSparePartRes.page,
        per_page: MotorSparePartRes.per_page,
        total_pages: MotorSparePartRes.total_pages,
        total: MotorSparePartRes.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }
  //   panels premissions
  const getSparePartsPermissions = async () => {
    try {
      setIsLoading(true)
      const res = await getAllActions(MotorSparePartsModuleId)
      const actionFields = res?.item?.fields
      const generatedColumns = generateColumns(actionFields)
      const filteredColumns = generatedColumns.filter(
        col =>
          col.accessor === "code" ||
          col.accessor === "name" ||
          col.accessor === "secondary_name"
      )

      if (actionFields) {
        setColumns([
          ...filteredColumns,
          {
            Header: buttonsTitles.actions,
            accessor: "actions",
            disableFilters: true,
            Cell: cellProps => {
              // Actions Icons in each row with its props
              const row = cellProps.row.original

              return (
                <div className="d-flex gap-2">
                  <SingleRowAction
                    actionName={"Replace"}
                    onClick={() => {
                      setReplaces(prev => uniqBy([...prev, row], "name"))
                    }}
                    iconClass="mdi mdi-autorenew"
                    record={row}
                  />
                  <SingleRowAction
                    actionName={"Repair"}
                    onClick={() => {
                      setRepairs(prev => uniqBy([...prev, row], "name"))
                    }}
                    iconClass="mdi mdi-hammer-screwdriver"
                    record={row}
                  />
                </div>
              )
            },
          },
        ])
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    getSparePartsPermissions()
  }, [])

  useEffect(() => {
    if (panelId) {
      getSpareParts({ page: 1, per_page: 10, panel_id: panelId })
    }
  }, [panelId])
  return (
    <div>
      {/* {loading && <TableSkeleton />} */}
      <TableContainer
        columns={columns}
        fullColumns={columns}
        data={spareParts}
        pagination={pagination}
        getData={getSpareParts}
        loading={loading}
        queryAddOn={{ panel_id: panelId }}
        removeActionsButtons={true}
        removeActionsColumns={true}
        resetInput={resetInput}
        setResetInput={setResetInput}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        sheetName={"panel"}
      />
    </div>
  )
}

export default InnerSparePartsTable
