/* Paths */
import { dashboardPaths } from "common/data/routePaths"
/*
Components
*/
// claim dashboard
import ClaimDashboard from "pages/Dashboard/ClaimDashboard"
import FnolDashboard from "pages/Dashboard/FnolDashboard"
import LiveTrackingDashboard from "pages/Dashboard/LiveTrackingDashboard"
import PayerDashboard from "pages/Dashboard/PayerDashboard"
// Production Dashboard
import ProductionDashboard from "pages/Dashboard/ProductionDashboard"
import StaticDashboard from "pages/Dashboard/StaticDashboard"
import staffTasks from "pages/General/Tasks"
import TaskCalender from "pages/General/Tasks/TasksCalender/Calender"

const {
  claimDashboard,
  productionDashboard,
  payerDashboard,
  fnolDashboard,
  liveTrackingDashboard,
  tasks,
} = dashboardPaths
export const DashboardsRoutes = [
  // Claim Dashboard
  { path: claimDashboard, component: ClaimDashboard },
  // live tracking Dashboard
  { path: liveTrackingDashboard, component: LiveTrackingDashboard },
  // Production Dashboard
  { path: productionDashboard, component: ProductionDashboard },
  //payer Dashboard
  { path: payerDashboard, component: PayerDashboard },
  //payer Dashboard
  { path: "/main-dashboard", component: StaticDashboard },
  //payer Dashboard
  { path: fnolDashboard, component: FnolDashboard },
  //staff tasks
  { path: tasks, component: staffTasks },
  { path: `/Calendar`, component: TaskCalender },
]
