import React, { useEffect, useState } from "react"
import FullCalendar from "@fullcalendar/react"
// import { BootstrapTheme } from "@fullcalendar/bootstrap"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"

import { Breadcrumbs, Card } from "@material-ui/core"
import { CardBody, Col, Container, Row } from "reactstrap"
import { getAllStaffTasks } from "helpers/General/staff_tasks_helper"
import moment from "moment"
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import UpdateStaffTask from "../UpdateStaffTask"
import { staffTasksModuleID } from "utils/modulesIds"
import { getSubmodules, getActions as onGetActions } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { staticLabels } from "common/data"

const TaskCalender = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetActions(staffTasksModuleID))
  }, [onGetActions])

  useEffect(() => {
    dispatch(getSubmodules(staffTasksModuleID))
  }, [onGetActions])

  const { fields: inputs } = useSelector((state) => ({
    fields: state.Module.fields,
    loading: state.Module.loading,
  }))
  console.log(inputs)
  const [staffTask, setStaffTask] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const [isUpdateModal, setIsUpdateModal] = useState(false)
  const [staffTaskToUpdate, setStaffTaskToUpdate] = useState(null)
  const [showAllTasks, setShowAllTasks] = useState(true)

  const newData = (data) => {
    return data.map((task) => ({
      ...task,
      users: task.users
        ? task.users.filter((user) => user.is_follower === 0)
        : [],
      followers: task.users
        ? task.users.filter((user) => user.is_follower === 1)
        : [],
      counter:
        task.due_date && task.start_date
          ? moment(task.due_date).diff(moment(task.start_date), "days")
          : 0,
    }))
  }
  const userId = JSON.parse(localStorage.getItem("authUser"))?.id

  const getStaffTasks = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
  }) => {
    try {
      setLoading(true)
      const staffTaskRes = await getAllStaffTasks({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        // user_id: showAllTasks ? "" : userId,
      })
      if (showAllTasks) {
        // Filter tasks without status_id = 1793
        setStaffTask(
          newData(
            staffTaskRes?.items?.filter((task) => task.status?.id !== 1793) ||
              []
          )
        )
      } else {
        // No filtering, just set the staff tasks as is
        setStaffTask(newData(staffTaskRes?.items || []))
      }

      setPagination({
        size: staffTaskRes.size,
        page: staffTaskRes.page,
        per_page: staffTaskRes.per_page,
        total_pages: staffTaskRes.total_pages,
        total: staffTaskRes.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    getStaffTasks({ page: 1, per_page: 1000 })
  }, [showAllTasks])

  const colorMapping = {
    "priority-normal": "bg-primary",
    "priority-high": "bg-danger",
  }
  //get events info
  const Tasks = staffTask.map((task) => ({
    id: task.id,
    title: task.title || "",
    start: task.start_date,
    className: colorMapping[task.priority?.slug] || "bg-primary",
    extendedProps: {
      priority: task.priority,
    },
  }))

  //on click update
  const handleClickUpdate = (event) => {
    const taskId = event.event.id
    setStaffTaskToUpdate(taskId)
    setIsUpdateModal(true)
  }

  // adding icon before task title
  const renderEventContent = (eventInfo) => {
    const priority = eventInfo.event.extendedProps.priority?.slug
    return (
      <div>
        {priority === "priority-high" && (
          <>
            <i className="mdi mdi-alert-circle-check-outline mr-4 fs-5"></i>{" "}
          </>
        )}
        {eventInfo.event.title}
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <div className="d-flex justify-content-between">
            <PageBreadCrumbAndTitle title="Calendar" />
            <div className="d-flex gap-2 align-items-center pt-1">
              <label htmlFor="" className="">
                {staticLabels.uncompletedTasks}
              </label>
              <div className="square-switch d-flex align-items-center">
                <input
                  type="checkbox"
                  id="square-switch2"
                  switch="info"
                  defaultChecked={showAllTasks}
                  onChange={(e) => setShowAllTasks((prev) => !prev)}
                />
                <label
                  htmlFor="square-switch2"
                  data-on-label="Yes"
                  data-off-label="No"
                />
              </div>
            </div>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col className="col-12">
                  {/* fullcalendar control */}
                  <div className="full-calendar-container p-3">
                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay",
                      }}
                      events={Tasks}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      eventClick={handleClickUpdate}
                      eventContent={renderEventContent}
                    />
                  </div>
                </Col>
              </Row>{" "}
            </CardBody>
          </Card>
        </Container>
        {isUpdateModal && (
          <UpdateStaffTask
            isUpdateModal={isUpdateModal}
            setIsUpdateModal={setIsUpdateModal}
            staffTaskToUpdate={staffTaskToUpdate}
            setStaffTaskToUpdate={setStaffTaskToUpdate}
            refetchList={() => {
              getStaffTasks({ page: 1, per_page: 100 })
            }}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default TaskCalender
