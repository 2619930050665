export const predefineds = {
  blacklistedId: 115,
  whitlistedId: 116,
}

export const memberStatus = {
  canecelled: { id: 713, slug: "memberStatus-cancelled" },
  expired: { id: 705, slug: "member-expired" },
  inActive: { id: 704, slug: "member-inactive" },
  terminated: { id: 702, slug: "member-terminated" },
  warned: { id: 701, slug: "member-warned" },
  delayed: { id: 700, slug: "member-delayed" },
}

export const endorsementTypes = {
  name: { id: 277, slug: "endorsement-beneficiary-name" },
  dateOfBirth: { id: 278, slug: "endorsement-beneficiary-dob" },
  policyCancel: { id: 284, slug: "endorsement-policy-cancellation" },
  addPlan: { id: 285, slug: "endorsement-add-co-participation" },
  removePlan: { id: 286, slug: "endorsement-stop-co-participation" },
  memberCancel: {
    id: 720,
    name: "Memeber Cancellation",
    slug: "endorsement-holder-cancellation",
  },
}
export const invoiceTypes = {
  generalService: { id: 295, slug: "" },
  feesInvoice: { id: 294, slug: "" },
  claimInvoice: { id: 293, slug: "" },
  groupInvoice: { id: 742, slug: "" },
}

export const actionsIds = {
  endorsementTaskActionId: 701,
  changeProductPremiumActionId: "",
}

export const eligibilityPredefinds = {
  eligible: 728,
  ileligible: 729,
}

export const TPAclasses = {
  a: 65,
  b: 66,
  sp: 67,
  c: 1700,
  k: 1699,
}

export const policyTypes = {
  invidual: { id: 564, name: "Individual", slug: "policyType_individual" },
  group: { id: 160, name: "Group", slug: "policyType_group" },
  family: { id: 772, name: "Family", slug: "policyType_family" },
}
