import { SearchTree } from "components/TP/Common/Forms"
import React, { Fragment, useState } from "react"
import { Col, Row } from "reactstrap"
import CarParts from "assets/images/survery/car_parts.png"
import { buttonsTitles } from "common/data"
import parse from "html-react-parser"
import DropZone from "components/TP/Common/DropZone"
const TwoSidesTable = () => {
  const [leftTable, setLeftTable] = useState([
    {
      subtitle: "جهة أمامية <br/> P2",
      items: [
        {
          issue: "طمبوني امامي",
          code: 1,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "جسر طمبوني امامي",
          code: 85,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "كلندر",
          code: 87,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "واجهة أمامية كاملة",
          code: 84,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "غطاء موتور",
          code: 4,
          change: false,
          fix: "",
          notes: "",
        },
      ],
    },

    {
      subtitle: "P2",
      items: [
        {
          issue: "سقف",
          code: 12,
          change: false,
          fix: "",
          notes: "",
        },
      ],
    },

    {
      subtitle: "جهة خلفية <br/> P2",
      items: [
        {
          issue: "طمبوني خلفى",
          code: 18,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "جسر طمبوني خلفى",
          code: 86,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "غطاء صندوق خلفى",
          code: 15,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "تنكة واجهة خلفية",
          code: 42,
          change: false,
          fix: "",
          notes: "",
        },
      ],
    },

    {
      subtitle: "زجاج M2",
      items: [
        {
          issue: "زجاج خلفى",
          code: 11,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "زجاج أمامى",
          code: 13,
          change: false,
          fix: "",
          notes: "",
        },
      ],
    },

    {
      subtitle: "ميكانيك <br/> M3",
      items: [
        {
          issue: "ترافرس امامي",
          code: 88,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "ترافرس خلفي",
          code: 89,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "رادياتور المكيف الخارجي",
          code: 90,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "رادياتور مياه",
          code: 91,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "مروحة",
          code: 92,
          change: false,
          fix: "",
          notes: "",
        },
      ],
    },

    {
      subtitle: "كهرباء <br/> E2",
      items: [
        {
          issue: "ارباغ السائق",
          code: 76,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "ارباغ امامي يمين",
          code: 77,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "حزام امان امامي شمال",
          code: 78,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "حزام امان امامي يمين",
          code: 79,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "تابلو",
          code: 93,
          change: false,
          fix: "",
          notes: "",
        },
        {
          issue: "غاز مكيف",
          code: "",
          change: false,
          fix: "",
          notes: "",
        },
      ],
    },
  ])

  const [rightTable, setRightTable] = useState([
    {
      subtitle: "طبشات <br/> T1",
      items: [
        {
          issue: "طبشة طمبوني أمامي",
          rightSide: {
            code: 5,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 7,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "طبشة او نكل رفراف امامي",
          rightSide: {
            code: 52,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 53,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "طبشة او نكل باب امامي",
          rightSide: {
            code: 50,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 51,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "طبشة او نكل باب خلفى",
          rightSide: {
            code: 54,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 55,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "طبشة او نكل رفراف خلفى",
          rightSide: {
            code: 56,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 57,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "طبشة طمبونى خلفى",
          rightSide: {
            code: 32,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 40,
            change: false,
            fix: "",
            notes: "",
          },
        },
      ],
    },

    {
      subtitle: "جنط <br/> M1",
      items: [
        {
          issue: "جنط أمامى",
          rightSide: {
            code: 9,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 10,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "جنط خلفى",
          rightSide: {
            code: 29,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 30,
            change: false,
            fix: "",
            notes: "",
          },
        },
      ],
    },

    {
      subtitle: "جوانب <br/> P1",
      items: [
        {
          issue: "رفرف أمامى",
          rightSide: {
            code: 19,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 20,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "باب أمامى",
          rightSide: {
            code: 21,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 23,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "باب خلفى",
          rightSide: {
            code: 25,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 27,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "رفرف خلفى",
          rightSide: {
            code: 33,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 39,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "مارش",
          rightSide: {
            code: 35,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 14,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "مراية باب أمامى",
          rightSide: {
            code: 36,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 37,
            change: false,
            fix: "",
            notes: "",
          },
        },
      ],
    },
    {
      subtitle: "مصابيح <br/> E1",
      items: [
        {
          issue: "ضوء أمامى",
          rightSide: {
            code: 6,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 8,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "ضوء خلفى",
          rightSide: {
            code: 16,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 17,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "إشارة أمامية",
          rightSide: {
            code: 72,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 73,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "ضوء ضباب خلفى",
          rightSide: {
            code: 74,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 75,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "بروجكتور أمامى",
          rightSide: {
            code: 2,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 3,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "ستوب خلفى شمال رفرف",
          rightSide: {
            code: 34,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 41,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "ستوب خلفى يمين صندوق",
          rightSide: {
            code: 94,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 95,
            change: false,
            fix: "",
            notes: "",
          },
        },
      ],
    },

    {
      subtitle: "هيكل سيارة <br/> C1 & P3",
      items: [
        {
          issue: "فحص أمامى",
          rightSide: {
            code: 62,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 63,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "فحص خلفى",
          rightSide: {
            code: 64,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 65,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "شاسي أمامى",
          rightSide: {
            code: 58,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 59,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "شاسي خلفى",
          rightSide: {
            code: 60,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 61,
            change: false,
            fix: "",
            notes: "",
          },
        },

        {
          issue: "عامود أمامى",
          rightSide: {
            code: 66,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 67,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "عامود وسط",
          rightSide: {
            code: 68,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 69,
            change: false,
            fix: "",
            notes: "",
          },
        },
        {
          issue: "عامود خلفى",
          rightSide: {
            code: 70,
            change: false,
            fix: "",
            notes: "",
          },
          leftSide: {
            code: 71,
            change: false,
            fix: "",
            notes: "",
          },
        },
      ],
    },
  ])

  const [selectedFiles, setSelectedFiles] = useState([])

  const fixParts = [
    { id: "0", name: "0" },
    { id: "25", name: "25" },
    { id: "50", name: "50" },
    { id: "75", name: "75" },
    { id: "100", name: "100" },
  ]

  return (
    <Fragment>
      <button onClick={() => {}} className="btn btn-primary mb-2 w-md">
        {buttonsTitles.save}
      </button>
      <Row className="ml-0 pl-0 gx-0">
        <Col md={5} className="m-0 p-0">
          <div
            className="table-responsive react-table"
            style={{ overflow: "auto", display: "block" }}
          >
            <table
              className="table"
              style={{
                position: "relative",
                // height: isLoading && "550px",
                height: "auto",
              }}
            >
              {/* First Header*/}
              <thead
                className="table-light table-nowrap sticky-header"
                id="__FPSC_ID_1_1683283486344"
              >
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th
                    className="ar-text font-size-18"
                    style={{ textAlign: "right" }}
                  >
                    الأضرار
                  </th>
                  <th></th>
                </tr>
              </thead>
              <thead
                className="table-light table-nowrap sticky-header"
                id="__FPSC_ID_1_1683283486344"
              >
                <tr>
                  <th style={{ textAlign: "right" }}>
                    <span className="py-2 text-ar">ملاحظات</span>
                  </th>
                  <th style={{ textAlign: "right" }} className=" text-ar">
                    تصليح
                  </th>
                  <th style={{ textAlign: "right" }} className=" text-ar">
                    غيار
                  </th>
                  <th>code</th>
                  <th style={{ textAlign: "right" }} className="ar-text">
                    طعج /ممزق 0%=0 25%=1
                    <br />
                    50%=2 75%=3 100%=4
                  </th>
                  <th>
                    <span className="opacity-0">hidden</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {leftTable.map((item, i) => (
                  <Fragment key={item.subtitle}>
                    {item.items.map((row, index) => (
                      <tr key={row.issue}>
                        <td style={{ textAlign: "right" }}>
                          <textarea
                            name="notes"
                            className="editable-table-textarea border-ddd"
                            style={{ height: "30px", width: "100%" }}
                            value={row.notes}
                            onChange={e => {
                              const modifiedTable = leftTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.code === row.code
                                        ? { ...i, notes: e.target.value }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setLeftTable(modifiedTable)
                            }}
                          />
                        </td>
                        <td className="align-middle">
                          <SearchTree
                            id="587"
                            name="change_reason_id"
                            placeholder="Please Select"
                            inputField={{ predefined_value: true }}
                            noLabel={true}
                            value={row.fix}
                            customOnChange={selectedValue => {
                              const modifiedTable = leftTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.code === row.code
                                        ? { ...i, fix: selectedValue }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setLeftTable(modifiedTable)
                            }}
                            onBlur={() => console.log("blur")}
                            fieldNames={{
                              children: "null",
                              label: "name",
                              value: "id",
                            }}
                            dropdownWidth="60px"
                            treeData={fixParts}
                          />
                        </td>
                        <td className="align-middle">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={row.change}
                            onChange={e => {
                              const modifiedTable = leftTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.code === row.code
                                        ? { ...i, change: !i.change }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setLeftTable(modifiedTable)
                            }}
                          />
                        </td>
                        <td className="align-middle">{row.code}</td>
                        <td
                          className="ar-text font-size-14 align-middle"
                          style={{ textAlign: "right" }}
                        >
                          {row.issue}
                        </td>
                        {index === 0 && item.subtitle && (
                          <td
                            rowSpan={item.items.length}
                            className={`border text-center align-middle ${
                              i % 2 === 0 ? "bg-gray-400" : "bg-gray-500"
                            }`}
                          >
                            <h5>{parse(item.subtitle)}</h5>
                          </td>
                        )}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* image */}
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "340px", marginTop: "-15px" }}
          >
            <img
              src={CarParts}
              alt="car parts"
              className="h-100 w-100"
              style={{ objectFit: "contain" }}
            />
          </div>
          {/* file upload */}
          <DropZone
            selectedFiles={selectedFiles}
            setselectedFiles={setSelectedFiles}
          />
        </Col>

        <Col md={7} style={{ height: "100%" }}>
          <div
            className="table-responsive react-table"
            style={{ overflow: "auto", display: "block" }}
          >
            <table
              className="table"
              style={{
                position: "relative",
                height: "auto",
              }}
            >
              {/* First Header*/}
              <thead
                className="table-light table-nowrap sticky-header"
                id="__FPSC_ID_1_1683283486344"
              >
                <tr>
                  <th
                    className="broder-r text-ar font-size-18"
                    colSpan={4}
                    style={{ textAlign: "right" }}
                  >
                    يمين
                  </th>
                  <th
                    className="broder-r text-ar font-size-18"
                    colSpan={4}
                    style={{ textAlign: "right" }}
                  >
                    شمال
                  </th>
                  <th
                    className="broder-r text-ar font-size-18"
                    colSpan={2}
                    style={{ textAlign: "right" }}
                  >
                    الأضرار
                  </th>
                </tr>
              </thead>
              <thead
                className="table-light table-nowrap sticky-header"
                id="__FPSC_ID_1_1683283486344"
              >
                <tr>
                  <th style={{ textAlign: "right" }} className="text-ar">
                    ملاحظات
                  </th>
                  <th style={{ textAlign: "right" }} className=" text-ar">
                    تصليح
                  </th>
                  <th style={{ textAlign: "right" }} className="text-ar">
                    غيار
                  </th>
                  <th>code</th>
                  <th style={{ textAlign: "right" }} className="text-ar">
                    ملاحظات
                  </th>
                  <th style={{ textAlign: "right" }} className="text-ar">
                    تصليح
                  </th>
                  <th style={{ textAlign: "right" }} className="text-ar">
                    غيار
                  </th>
                  <th>code</th>
                  <th className="text-ar" style={{ textAlign: "right" }}>
                    طعج /ممزق 0%=0 25%=1
                    <br />
                    50%=2 75%=3 100%=4
                  </th>
                  <th>
                    <span className="opacity-0">hidden</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rightTable.map((item, i) => (
                  <Fragment key={item.subtitle}>
                    {item.items.map((row, index) => (
                      <tr key={row.issue}>
                        {/* right */}
                        <td>
                          <textarea
                            name="notes"
                            // cols={20}
                            className="editable-table-textarea border-ddd"
                            style={{ height: "30px" }}
                            value={row.leftSide.notes}
                            onChange={e => {
                              const modifiedTable = rightTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.leftSide.code === row.leftSide.code
                                        ? {
                                            ...i,
                                            leftSide: {
                                              ...i.leftSide,

                                              notes: e.target.value,
                                            },
                                          }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setRightTable(modifiedTable)
                            }}
                          />
                        </td>
                        <td className="align-middle">
                          <SearchTree
                            id="587"
                            name="change_reason_id"
                            placeholder="Please Select"
                            inputField={{ predefined_value: true }}
                            noLabel={true}
                            value={row.leftSide.fix}
                            customOnChange={selectedValue => {
                              const modifiedTable = rightTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.leftSide.code === row.leftSide.code
                                        ? {
                                            ...i,
                                            leftSide: {
                                              ...i.leftSide,
                                              fix: selectedValue,
                                            },
                                          }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setRightTable(modifiedTable)
                            }}
                            onBlur={() => console.log("blur")}
                            fieldNames={{
                              children: "children",
                              label: "name",
                              value: "id",
                            }}
                            dropdownWidth="60px"
                            treeData={fixParts}
                            specificKey="children"
                          />
                        </td>
                        <td className="align-middle">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={row.leftSide.change}
                            onChange={e => {
                              const modifiedTable = rightTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.leftSide.code === row.leftSide.code
                                        ? {
                                            ...i,
                                            leftSide: {
                                              ...i.leftSide,
                                              change: !i.leftSide.change,
                                            },
                                          }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setRightTable(modifiedTable)
                            }}
                          />
                        </td>
                        <td className="align-middle">{row.leftSide.code}</td>
                        {/* left */}
                        <td>
                          <textarea
                            name="notes"
                            // cols={20}
                            className="editable-table-textarea border-ddd"
                            style={{ height: "30px" }}
                            value={row.rightSide.notes}
                            onChange={e => {
                              const modifiedTable = rightTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.rightSide.code === row.rightSide.code
                                        ? {
                                            ...i,
                                            rightSide: {
                                              ...i.rightSide,
                                              notes: e.target.value,
                                            },
                                          }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setRightTable(modifiedTable)
                            }}
                          />
                        </td>
                        <td className="align-middle">
                          <SearchTree
                            id="587"
                            name="change_reason_id"
                            placeholder="Please Select"
                            inputField={{ predefined_value: true }}
                            noLabel={true}
                            value={row.rightSide.fix}
                            customOnChange={selectedValue => {
                              const modifiedTable = rightTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.rightSide.code === row.rightSide.code
                                        ? {
                                            ...i,
                                            rightSide: {
                                              ...i.rightSide,
                                              fix: selectedValue,
                                            },
                                          }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setRightTable(modifiedTable)
                            }}
                            onBlur={() => console.log("blur")}
                            // treeData={regionTree}
                            fieldNames={{
                              children: "children",
                              label: "name",
                              value: "id",
                            }}
                            treeData={fixParts}
                            dropdownWidth="60px"
                          />
                        </td>
                        <td className="align-middle">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={row.rightSide.change}
                            onChange={e => {
                              const modifiedTable = rightTable.map(tabl => {
                                if (tabl.subtitle === item.subtitle) {
                                  return {
                                    ...tabl,
                                    items: tabl.items.map(i =>
                                      i.rightSide.code === row.rightSide.code
                                        ? {
                                            ...i,
                                            rightSide: {
                                              ...i.rightSide,
                                              change: !i.rightSide.change,
                                            },
                                          }
                                        : i
                                    ),
                                  }
                                } else {
                                  return tabl
                                }
                              })
                              setRightTable(modifiedTable)
                            }}
                          />
                        </td>
                        <td className="align-middle">{row.rightSide.code}</td>
                        <td
                          className="ar-text font-size-14 align-middle"
                          style={{ textAlign: "right" }}
                        >
                          {row.issue}
                        </td>
                        {index === 0 && item.subtitle && (
                          <td
                            rowSpan={item.items.length}
                            className={`border text-center align-middle ${
                              i % 2 === 0 ? "bg-gray-400" : "bg-gray-500"
                            }`}
                          >
                            <h5>{parse(item.subtitle)}</h5>
                          </td>
                        )}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default TwoSidesTable
