import PropTypes from "prop-types"
import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next"

import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import LineApexChart from "components/TP/Charts/LineApexChart"
import LineColumnApexChart from "components/TP/Charts/LineColumnApexChart"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import { dashboardsID, mainDashboardID } from "utils/modulesIds"
import ReactApexChart from "react-apexcharts"

const StaticDashboard = (props) => {
  const { module } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === mainDashboardID),
    loading: state.Sidebar?.loading,
  }))

  const reports = [
    {
      title: "Individual <br/> Loss Ratio",
      value: "110",
      badgeValue: "+ 1.2%",
      dir: "up",
      color: "yellowOrange",
      desc: "From Last Year",
    },
    {
      title: "Family <br/> Loss Ratio",
      value: "78",
      badgeValue: "+ 9%",
      dir: "down",
      color: "success",
      desc: "From Last Year",
    },
    {
      title: "Group <br/> Loss Ratio",
      value: "96",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "down",
      desc: "From Last Year",
    },
    {
      title: "Individual <br/> Retention Ratio",
      value: "20",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Family <br/> Retention Ratio",
      value: "80",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Group <br/> Retention Ratio",
      value: "96",
      badgeValue: "+ 2.3%",
      color: "yellowOrange",
      dir: "down",
      desc: "From Last Year",
    },
  ]

  //claim boxes
  const claimsCountData = [
    { label: "In-Patient", value: "1,650" },
    { label: "Pharmacy", value: 200 },
    { label: "Doctor Visits", value: 312 },
    { label: "Ambulatory", value: 800 },
  ]
  const claimsAmountData = [
    { label: "In-Patient", value: "$ 62,000" },
    { label: "Pharmacy", value: "$ 1,500" },
    { label: "Doctor Visits", value: "$ 2,600" },
    { label: "Ambulatory", value: "$ 20,000" },
  ]

  const ExpirePoliciesData = [4, 9, 16]
  const ActivePoliciesData = [4723, 820, 1690]

  const options = {
    chart: {
      width: "100%",
      height: "400px",
      type: "pie",
    },
    labels: ["Group", "Individual", "Family"],
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 150,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },

    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  }

  //Top Diseases
  const diseasesdata = [
    {
      name: "",
      data: [
        5000, 8000, 12000, 14000, 15000, 16000, 19000, 21000, 23000, 25000,
      ],
    },
  ]
  const diseasesOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["rgba(52, 195, 143, 0.85)"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Blood & blood-forming organ diseases",
        "Hypertensive disease ",
        "Persons Encountering Health Services",
        "Thyroid gland disease",
        "Rheumatism, excluding back",
        "Other female genital tract disorders",
        "Breast disorders",
        "Dorsopathies",
        "Arthropathies & related disorders ",
        "Symptoms",
      ],
    },
  }

  //Top providers
  const ProvidersData = [
    {
      name: "",
      data: [
        28.208, 32.638, 33.1, 52.002, 61.0, 62.429, 67.748, 74.789, 80.236,
        91.051,
      ],
    },
  ]
  const ProvidersOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#00BDFF"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Clinique Du Levant",
        "Lebanese Healthcare Management ... ",
        "LAUMC-St. John Hospital",
        "Centre Hosp CHU-Notre Dame...",
        "Hopital Libanais Geitaoui",
        "Rizk Hospit Hopital St. Joseph",
        "University Medica University Medical Center",
        "St. George Hospital ",
        "Bellevue Medical Center ",
        "Hotel-Dieu De France",
      ],
    },
  }

  //claim overview
  const stackedColumnsoptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    colors: ["#556ee6", "#F1B44C", "#34C38F"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  const stackedColumnData = [
    {
      name: "PM",
      data: [5000, 15000, 4000, 24000, 7500, 12000, 11000, 8000, 27000, 5000],
    },
    {
      name: "AMB",
      data: [5000, 10000, 7500, 2000, 5000, 10000, 7000, 10000, 5000, 5000],
    },
    {
      name: "IN",
      data: [8000, 15000, 7000, 6500, 12000, 22000, 6000, 7000, 8000, 5000],
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle pageType="ddd" title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {reports.map((report) => (
              <Col md={2} key={report.title}>
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center mb-3">
                      <h6>{parse(report.title)}</h6>
                    </div>
                    <div className="text-muted mt-3">
                      <h4>
                        {report.value}%
                        <i
                          className={
                            "mdi mdi-chevron-" +
                            report.dir +
                            " ms-1 text-" +
                            report.color
                          }
                        />
                      </h4>
                      <div className="d-flex">
                        <span
                          className={
                            "badge badge-soft-" + report.color + " font-size-12"
                          }
                        >
                          {" "}
                          {report.badgeValue}{" "}
                        </span>{" "}
                        <span className="ms-2 text-truncate">
                          {report.desc}
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-4 card-title">Members Overview</div>
                  <LineApexChart dataColors='["--bs-blue", "--bs-green"]' />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="card-title mb-4">Business Overview </div>
                  <LineColumnApexChart dataColors='["--bs-danger","--bs-blue", "--bs-green"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Claim Overview</h4>
                  <ReactApexChart
                    options={stackedColumnsoptions}
                    series={stackedColumnData}
                    type="bar"
                    height="360"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>

            {/* <Col md={3}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Claims Count</CardTitle>
                  <div className="container">
                    <Row className="d-flex justify-content-center">
                      <Col md={8}>
                        <div className="mb-4 text-center">
                          <div className="mb-2 align-self-center ">
                            <i
                              style={{
                                fontSize: "50px",
                                color: "#766ee0",
                              }}
                              className="bx bx-shield-quarter "
                            ></i>
                          </div>
                          <div className="mb-2 font-size-18 fw-bold ">
                            2,836
                          </div>
                          <div className=" text-muted mb-2">Claims</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {claimsCountData.map((item) => (
                          <div
                            key={item.label}
                            className="mb-3 d-flex justify-content-between"
                          >
                            <div>{item.label}</div>
                            <div className="font-size-15 fw-bold">
                              {item.value}
                            </div>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Claims Amount</CardTitle>
                  <div className="container">
                    <Row className="d-flex justify-content-center">
                      <Col md={8}>
                        <div className="mb-4 text-center">
                          <div className="mb-2 align-self-center ">
                            <i
                              style={{
                                fontSize: "50px",
                                color: "#48bea1",
                              }}
                              className="bx bx-shield-quarter"
                            ></i>
                          </div>
                          <div className="mb-2 font-size-18 fw-bold ">
                            $ 85,000
                          </div>
                          <div className=" text-muted mb-2">Claims</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {claimsAmountData.map((item) => (
                          <div
                            key={item.label}
                            className="mb-3 d-flex justify-content-between"
                          >
                            <div>{item.label}</div>
                            <div className="font-size-15 fw-bold">
                              {item.value}
                            </div>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col md={3} className="d-flex">
              <Card className="flex-grow-1">
                <CardBody>
                  <h4 className="card-title mb-5">Due To Expire Policies</h4>
                  <div className="mb-5">
                    <ReactApexChart
                      type="pie"
                      height="350px"
                      width="342px"
                      options={options}
                      series={ExpirePoliciesData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Active Policies</h4>
                  <div className="mt-2">
                    <ReactApexChart
                      className="d-flex justify-content-center align-items-center"
                      type="pie"
                      height="345px"
                      width="350px"
                      options={options}
                      series={ActivePoliciesData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Top Providers</h4>
                  <div>
                    <ReactApexChart
                      options={ProvidersOptions}
                      series={ProvidersData}
                      type="bar"
                      height="350"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title ">Top Diseases</h4>
                  <div>
                    <ReactApexChart
                      options={diseasesOptions}
                      series={diseasesdata}
                      type="bar"
                      height="350"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mb-4 h4 card-title">
                    Due To Expire Group Policies
                  </div>
                  <div className="mb-2 row"></div>
                  <div className="table-responsive">
                    <table className="align-middle table-nowrap mb-0 table table-hover">
                      <thead className="table-light">
                        <tr>
                          <th colSpan="1" className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Policy Number
                            </div>
                          </th>
                          <th colSpan="1" className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Policy Name
                            </div>
                          </th>
                          <th colSpan="1" className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Broker Name
                            </div>
                          </th>
                          <th colSpan="1" className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Gross Premiums
                            </div>
                          </th>
                          <th colSpan="1" className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Expiry Date
                            </div>
                          </th>
                          <th colSpan="1" className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Remaining Date
                            </div>
                          </th>

                          <th colSpan="1" className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              View Details
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className="text-body fw-bold" href="/dashboard">
                              #SK2545
                            </a>
                          </td>
                          <td>IPT</td>
                          <td>
                            <span> Rima Karim Khoury</span>
                          </td>
                          <td>$39,200</td>
                          <td>24 Jan, 2024</td>
                          <td>
                            <span className="font-size-11 badge-soft-danger badge bg-secondary">
                              14 Days
                            </span>
                          </td>

                          <td>
                            <button
                              type="button"
                              className="btn-sm btn-rounded btn btn-primary"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="text-body fw-bold" href="/dashboard">
                              #SK2545
                            </a>
                          </td>
                          <td>Gefinor Rotana</td>
                          <td>
                            <span> Elias Farid Mansour</span>
                          </td>
                          <td>$16,000</td>
                          <td>16 Feb, 2024</td>
                          <td>
                            <span className="font-size-11 badge-soft-danger badge bg-secondary">
                              30 Days
                            </span>
                          </td>

                          <td>
                            <button
                              type="button"
                              className="btn-sm btn-rounded btn btn-primary"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="text-body fw-bold" href="/dashboard">
                              #SK2545
                            </a>
                          </td>
                          <td>BMA paints</td>
                          <td>
                            <span> Nour Samir Abi Nader</span>
                          </td>
                          <td>$12,359</td>
                          <td>16 Feb, 2024</td>
                          <td>
                            <span className="font-size-11 badge-soft-danger badge bg-secondary">
                              30 Days
                            </span>
                          </td>

                          <td>
                            <button
                              type="button"
                              className="btn-sm btn-rounded btn btn-primary"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

StaticDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(StaticDashboard)
