// paths
import { commonDataPaths } from "common/data/routePaths"
import { lazy } from "react"
// components

// banks
const Banks = lazy(() => import("pages/CommonData/Banks"))
const BankForms = lazy(() => import("pages/CommonData/Banks/BankForms"))
// Currencies
const Currencies = lazy(() => import("pages/CommonData/Currencies"))
// services
const Services = lazy(() => import("pages/CommonData/Services"))
const ServiceForms = lazy(() =>
  import("pages/CommonData/Services/ServiceForms")
)
// benefits
const Benefits = lazy(() => import("pages/CommonData/Benefits"))
const BenefitForms = lazy(() =>
  import("pages/CommonData/Benefits/BenefitForms")
)
const UploadBenefits = lazy(() =>
  import("pages/CommonData/Benefits/UploadBenefits")
)
// medical codes
const MedicalCodes = lazy(() => import("pages/CommonData/MedicalCodes"))
const MedicalCodeForms = lazy(() =>
  import("pages/CommonData/MedicalCodes/MedicalCodeForms")
)

// Event Manage
const Events = lazy(() => import("pages/CommonData/Events"))

// priority payer codes
const PriorityPayerCodes = lazy(() =>
  import("pages/CommonData/PriorityPayerCodes")
)
const PriorityCodeForms = lazy(() =>
  import("pages/CommonData/PriorityPayerCodes/PriorityCodeForms")
)

// pci rules
const PCIRules = lazy(() => import("pages/CommonData/PCIRules"))
const PCIRulesForms = lazy(() =>
  import("pages/CommonData/PCIRules/PCIRulesForms")
)
// pci rules
const TOBTemplates = lazy(() => import("pages/CommonData/TOB"))
const TOBTemplatesForms = lazy(() =>
  import("pages/CommonData/TOB/TOBTemplatesForms")
)

const UploadMedicalCodes = lazy(() =>
  import("pages/CommonData/MedicalCodes/UploadMedicalCodes")
)
const Covers = lazy(() => import("pages/CommonData/Covers"))
const LOB = lazy(() => import("pages/CommonData/LineOfBusiness"))

// destructure commonDataPaths object

const {
  banks,
  currencies,
  services,
  benefits,
  medical_codes,
  events,
  pciRules,
  tobTemplates,
  covers,
  lob,
} = commonDataPaths

export const CommonDataRoutes = [
  // Banks
  { path: banks, component: Banks },
  { path: `${banks}/add`, component: BankForms },
  { path: `${banks}/edit/:id`, component: BankForms },

  // currencies
  { path: currencies, component: Currencies },

  // Services
  { path: services, component: Services },
  { path: `${services}/add`, component: ServiceForms },
  { path: `${services}/edit/:id`, component: ServiceForms },
  { path: `${services}/view/:id`, component: ServiceForms },

  // benefits
  { path: benefits, component: Benefits },
  { path: `${benefits}/add`, component: BenefitForms },
  { path: `${benefits}/upload`, component: UploadBenefits },
  { path: `${benefits}/edit/:id`, component: BenefitForms },
  { path: `${benefits}/view/:id`, component: BenefitForms },
  // medical codes
  { path: medical_codes, component: MedicalCodes },
  { path: `${medical_codes}/add`, component: MedicalCodeForms },
  { path: `${medical_codes}/upload`, component: UploadMedicalCodes },
  { path: `${medical_codes}/edit/:id`, component: MedicalCodeForms },
  { path: `${medical_codes}/view/:id`, component: MedicalCodeForms },

  // Event Manager
  { path: events, component: Events },

  // Priority Payer Code (standard codes)
  { path: "/priority/:id/standard-code", component: PriorityPayerCodes },
  { path: "/priority/:priorityId/add-standard", component: PriorityCodeForms },
  { path: "/priority/:priorityId/edit/:id", component: PriorityCodeForms },

  //pci rules module
  { path: pciRules, component: PCIRules },
  { path: `${pciRules}/add`, component: PCIRulesForms },
  { path: `${pciRules}/edit/:id`, component: PCIRulesForms },
  { path: `${pciRules}/view/:id`, component: PCIRulesForms },

  //TOB Templates module
  { path: tobTemplates, component: TOBTemplates },
  { path: `${tobTemplates}/add`, component: TOBTemplatesForms },
  { path: `${tobTemplates}/edit/:id`, component: TOBTemplatesForms },
  { path: `${tobTemplates}/view/:id`, component: TOBTemplatesForms },

  //covers
  { path: covers, component: Covers },

  //line of business
  { path: lob, component: LOB },
]
